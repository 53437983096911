<template>
    <auth-layout>
        <div v-if="isSend">
            <p>Veuillez verifier votre boite courriel</p>
            <div class="mt-6 flex flex-row items-center justify-center">
                <button
                    type="button"
                    @click.prevent="restart"
                    class="btn w-full">
                    Recommencer
                </button>
            </div>
        </div>

        <form
            @submit.prevent="forgot"
            v-else>
            <div class="input-container">
                <label
                    for="email"
                    class="">
                    Adresse courriel
                </label>
                <div class="c__input">
                    <input
                        id="email"
                        type="email"
                        required
                        v-model="email"
                        name="email" />
                </div>
                <p
                    class="error"
                    v-if="errors.email">
                    {{ errors.email }}
                </p>
            </div>

            <div class="mt-6">
                <button
                    type="submit"
                    class="btn w-full">
                    Changer le mot de passe
                </button>
            </div>
            <div class="mt-6 flex flex-row items-center justify-center">
                <router-link
                    to="login"
                    class="btn--outline">
                    Se connecter
                </router-link>
            </div>
        </form>
    </auth-layout>
</template>
<script>
import * as types from "store/types"
import AuthLayout from "views/layouts/AuthLayout"

export default {
    name: "ForgotPassword",
    components: { AuthLayout },
    data() {
        return {
            isSend: false,
            email: "",
            errors: {}
        }
    },
    methods: {
        restart() {
            this.email = ""
            this.isSend = false
            this.errors = {}
        },
        forgot() {
            this.errors = {}
            this.$store
                .dispatch(types.FORGOT_PWD, this.email)
                .then((res) => {
                    this.isSend = true
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
