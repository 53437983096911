<template>
    <div>
        <div class="input-container">
            <label>Nom</label>
            <div class="c__input">
                <input
                    type="text"
                    name="name"
                    v-model="school.name" />
            </div>
            <p
                class="error"
                v-if="errors.name">
                {{ errors.name }}
            </p>
        </div>
        <div class="input-container">
            <label>Adresse</label>
            <div class="c__input">
                <input
                    type="text"
                    name="address"
                    v-model="school.address" />
            </div>
            <p
                class="error"
                v-if="errors.address">
                {{ errors.address }}
            </p>
        </div>
        <div class="input-container">
            <label>Adresse complete</label>
            <div class="c__input">
                <input
                    type="text"
                    name="fullAddress"
                    v-model="school.fullAddress" />
            </div>
            <p
                class="error"
                v-if="errors.fullAddress">
                {{ errors.fullAddress }}
            </p>
        </div>
        <div class="input-container">
            <label>Ville</label>
            <div class="c__input">
                <input
                    type="text"
                    name="city"
                    v-model="school.city" />
            </div>
            <p
                class="error"
                v-if="errors.city">
                {{ errors.city }}
            </p>
        </div>
        <div class="grid grid-cols-2 gap-6">
            <div class="input-container">
                <label>Région</label>
                <div class="c__select">
                    <select
                        class="form-select"
                        name="region"
                        v-model="school.region">
                        <option
                            value=""
                            selected>
                            Sélectionner
                        </option>
                        <option value="QC">Québec</option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.region">
                    {{ errors.region }}
                </p>
            </div>
            <div class="input-container">
                <label>Pays</label>
                <div class="c__select">
                    <select
                        class="form-select"
                        name="country"
                        v-model="school.country">
                        <option
                            value=""
                            selected>
                            Sélectionner
                        </option>
                        <option value="CA">Canada</option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.country">
                    {{ errors.country }}
                </p>
            </div>
        </div>

        <div class="grid gap-6 grid-cols-2">
            <div class="input-container">
                <label>Latitude</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="lat"
                        v-model="school.lat" />
                </div>
                <p
                    class="error"
                    v-if="errors.lat">
                    {{ errors.lat }}
                </p>
            </div>
            <div class="input-container">
                <label>Longitude</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="lng"
                        v-model="school.lng" />
                </div>
                <p
                    class="error"
                    v-if="errors.lng">
                    {{ errors.lng }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "school-form",
    components: {},
    props: {
        school: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    created() {
        //todo
    },
    computed: {},
    methods: {}
}
</script>
