<template>
    <div
        class="o-loading"
        :class="{ 'is-loading': isLoading }">
        <div
            class="o-loading--content"
            :class="classContent">
            <slot></slot>
        </div>
        <transition name="anim-loader">
            <Loader v-if="isLoading"></Loader>
        </transition>
    </div>
</template>

<script>
import Loader from "components/globals/Loader"

export default {
    name: "loading-container",
    components: {
        Loader
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        classContent: String
    }
}
</script>

<style lang="scss">
.o-loading {
    min-height: 100px;
    position: relative;

    > .c__loader {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
    }

    &.is-loading {
        position: relative;

        > .c__loader {
            display: inline-block;
        }

        .o-loading--content {
            opacity: 0.7;
            pointer-events: none;

            * {
                pointer-events: none;
            }
        }
    }
}

@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
