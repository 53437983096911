<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/classrooms"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux classes
            </router-link>
            <h1 class="pb-6">Modifier cette classe</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="classroom">
                    <classroom-form
                        :classroom="classroom"
                        :errors="errors" />

                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer la classe
                        </button>
                        <button
                            type="submit"
                            class="btn">
                            Modifier
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import ClassroomForm from "views/classrooms/ClassroomForm"

export default {
    name: "modify-classroom",
    components: {
        ClassroomForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            classroom: null,
            studentIdCount: 0,
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.isLoading = true
        this.$store
            .dispatch(types.GET_CLASSROOM_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.classroom = res
                this.classroom.teacherId = this.classroom.teacherId === null ? "" : this.classroom.teacherId
                this.classroom.schoolId = this.classroom.schoolId === null ? "" : this.classroom.schoolId

                this.isLoading = false
            })
            .catch((err) => {
                this.isLoading = false
                this.$router.replace("/classrooms")
            })
    },
    methods: {
        remove() {
            if (
                !window.confirm(
                    "Effacer cette classe effacera tous les élèves aussi. Êtes-vous sûr de vouloir continuer ?"
                )
            ) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_CLASSROOM, this.classroom.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/classrooms")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true

            this.classroom.teacherId = this.classroom.teacherId === "" ? null : this.classroom.teacherId
            this.classroom.schoolId = this.classroom.schoolId === "" ? null : this.classroom.schoolId

            this.$store
                .dispatch(types.UPDATE_CLASSROOM, this.classroom)
                .then((res) => {
                    this.classroom = res

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: "Classe",
                        message: "La classe a été enregistré",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
