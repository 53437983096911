import axios from "axios"
import EventBus, { EVENTS } from "@/eventbus"
import router from "config/router"

const ajax = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/v1/admin",
    timeout: 15000
})

// send cookie with request
// axios.defaults.withCredentials = true

ajax.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        const data = response.data
        if (!data) {
            return data
        }
        // trigger flash from response
        if (typeof data.flash !== "undefined" && data.flash !== "") {
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: "Succès",
                message: data.flash,
                type: "success"
            })
        }

        return data
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        let data = error.response ? error.response.data : null
        if (!data) {
            return Promise.reject(error)
        }

        if (error.response.status === 401) {
            //no more valid token, go to auth login
            router.push("/login")
        }

        // trigger flash from response
        if (typeof data.flash !== "undefined" && data.flash !== "") {
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: "Erreur",
                message: data.flash,
                type: "warning"
            })
        }

        //init to object because 404
        if (error.response.status === 404) {
            data = {
                success: false
            }
        }

        //init to empty object by default if not provided
        if (typeof data.errors === "undefined") {
            data.errors = {}
        }

        return Promise.reject(data)
    }
)

export default ajax
