<template>
    <div class="uploads">
        <loader v-if="loading"></loader>
        <div
            class="flex flex-row justify-start items-center flex-wrap -mx-2"
            v-if="images.length > 0">
            <div
                v-for="upload in images"
                :key="upload.path"
                @click.prevent="select(upload)"
                class="m-2 cursor-pointer border-solid"
                :class="{
                    'border-2 border-accent': selectedPath === upload.path,
                    'border border-gray-200': selectedPath !== upload.path
                }">
                <img
                    loading="lazy"
                    :src="upload.url"
                    class="block object-cover w-16 h-16" />
            </div>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import Loader from "components/globals/Loader"

export default {
    name: "uploads-gallery",
    components: { Loader },
    props: {
        folder: {
            type: String,
            default: "all"
        },
        selectedPath: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        images() {
            if (typeof this.$store.state.data.uploadsByFolder[this.folder] === "undefined") {
                return []
            }
            return this.$store.state.data.uploadsByFolder[this.folder].images
        }
    },
    created() {
        this.loading = true
        this.$store
            .dispatch(types.GET_UPLOADS, this.folder)
            .then((images) => {
                this.loading = false
            })
            .catch(() => {
                this.loading = false
            })
    },
    methods: {
        select(upload) {
            this.$emit("select", upload)
        }
    }
}
</script>
