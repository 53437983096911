<template>
    <div class="py-12">
        <div class="container">
            <div class="flex flex-row justify-between items-start border-b border-gray-200 py-6">
                <div class="w-full md:w-1/6 md:pr-6">
                    <h2>Votre profil</h2>
                    <p>Vos informations de base</p>
                </div>
                <div class="w-full md:w-4/6 md:pl-6">
                    <div class="card">
                        <loading-container :is-loading="updateUserLoading">
                            <form @submit.prevent="updateUser">
                                <div class="grid gap-3 grid-cols-2">
                                    <div class="input-container col-span-1">
                                        <label
                                            for="firstName"
                                            class="">
                                            Prénom
                                        </label>
                                        <div class="c__input">
                                            <input
                                                id="firstName"
                                                type="text"
                                                required
                                                name="firstName"
                                                v-model="firstName" />
                                        </div>
                                        <p
                                            class="error"
                                            v-if="errors.firstName">
                                            {{ errors.firstName }}
                                        </p>
                                    </div>
                                    <div class="input-container col-span-1">
                                        <label
                                            for="lastName"
                                            class="">
                                            Nom
                                        </label>
                                        <div class="c__input">
                                            <input
                                                id="lastName"
                                                type="text"
                                                required
                                                name="lastName"
                                                v-model="lastName" />
                                        </div>
                                        <p
                                            class="error"
                                            v-if="errors.lastName">
                                            {{ errors.lastName }}
                                        </p>
                                    </div>

                                    <div class="input-container col-span-2">
                                        <label
                                            for="email"
                                            class="flex flex-row justify-between items-center">
                                            Adresse courriel
                                        </label>
                                        <div class="c__input">
                                            <input
                                                id="email"
                                                type="email"
                                                required
                                                name="email"
                                                v-model="email" />
                                        </div>
                                        <p
                                            class="error"
                                            v-if="errors.email">
                                            {{ errors.email }}
                                        </p>
                                    </div>

                                    <div class="input-container col-span-2">
                                        <label
                                            for="password"
                                            class="flex flex-row justify-between items-center">
                                            Mot de passe
                                            <button
                                                type="button"
                                                class="btn--text"
                                                @click.prevent="changePassword = true">
                                                Modifier le mot de passe
                                            </button>
                                        </label>
                                        <div class="c__input">
                                            <input
                                                class="form-control"
                                                type="password"
                                                name="password"
                                                id="password"
                                                v-model="currentPassword"
                                                placeholder="*********"
                                                :disabled="!changePassword"
                                                autocomplete="new-password"
                                                minlength="6" />
                                        </div>
                                    </div>
                                    <div
                                        class="input-container"
                                        v-if="changePassword">
                                        <label for="newPassword"> Nouveau mot de passe </label>
                                        <div class="c__input">
                                            <input
                                                type="password"
                                                name="password"
                                                id="newPassword"
                                                v-model="newPassword"
                                                autocomplete="new-password"
                                                minlength="6" />
                                        </div>
                                    </div>
                                    <div
                                        class="input-container"
                                        v-if="changePassword">
                                        <label for="confirmPassword"> Confirmé le nouveau mot de passe </label>
                                        <div class="c__input">
                                            <input
                                                class="form-control"
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                v-model="confirmPassword"
                                                autocomplete="new-password"
                                                minlength="6" />
                                        </div>
                                    </div>
                                </div>
                                <div class="card__save bg-gray-100">
                                    <div class="flex flex-row justify-end items-center">
                                        <button
                                            type="submit"
                                            class="btn"
                                            :class="{ disabled: !userHasChanged && !passwordHasChanged }">
                                            Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </loading-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import { isEmpty } from "@globals/vue/utils"

export default {
    name: "profile",
    components: { LoadingContainer },
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            updateUserLoading: false,

            changePassword: false,

            currentPassword: "",
            newPassword: "",
            confirmPassword: "",

            errors: {}
        }
    },
    created() {
        const { firstName, lastName, email } = this.$store.state.auth.user
        this.firstName = firstName
        this.lastName = lastName
        this.email = email
    },
    computed: {
        userHasChanged() {
            const { firstName, lastName, email } = this.$store.state.auth.user

            return this.lastName !== lastName || this.firstName !== firstName || this.email !== email
        },
        passwordHasChanged() {
            return !isEmpty(this.currentPassword) && !isEmpty(this.newPassword) && !isEmpty(this.confirmPassword)
        }
    },
    methods: {
        async updateUser() {
            this.updateUserLoading = true

            if (this.userHasChanged) {
                await this.$store.dispatch(types.UPDATE_USER, {
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    ...(this.passwordHasChanged
                        ? {
                              newPassword: this.newPassword,
                              currentPassword: this.currentPassword
                          }
                        : {})
                })
            }

            this.updateUserLoading = false
        }
    }
}
</script>
