import * as types from "store/types"
import ajax from "config/ajax"
import { cloneDeep } from "lodash"
import { DEFAULT_LANG } from "@globals/constants"
// Static

// initial state, with craft backend or empty
const state = {
    DEFAULT_LANG: DEFAULT_LANG,
    schoolsById: {},
    gameTypes: [],
    productCategories: [],

    students: [],
    classrooms: [],
    words: [],
    wordGroups: [],
    schoolUsers: [],
    products: [],

    uploadsByFolder: {}
}

// getters, make function easy to access by vue
const getters = {
    getTraduction:
        (state) =>
        (traductions, lang = state.DEFAULT_LANG) => {
            if (!traductions) {
                return null
            }
            if (traductions.length === 0) return null
            return traductions.find((t) => t.lang === lang) || null
        },
    getTeacher: (state) => (teacherId) => {
        return state.schoolUsers.find((t) => t.id === teacherId && t.isTeacher)
    },
    getSchool: (state) => (schoolId) => {
        return state.schoolsById[schoolId] || null
    },
    dataToSelector:
        (state) =>
        (data, labelKey = "label", valueKey = "id") => {
            return data.map((d) => {
                return {
                    label: d[labelKey],
                    value: d[valueKey]
                }
            })
        }
}

// actions
const actions = {
    // WORD GROUPS

    [types.GET_WORD_GROUPS](store, payload) {
        return ajax.get("/word-groups").then((res) => {
            store.commit(types.GET_WORD_GROUPS, res.wordGroups)
            return Promise.resolve(res.wordGroups)
        })
    },
    [types.GET_WORD_GROUP_BY_UUID](store, uuid) {
        //dont check for exist, since uuid will return full data
        return ajax.get(`/word-groups/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_WORD_GROUP_BY_UUID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.CREATE_WORD_GROUP](store, payload) {
        return ajax.post("/word-groups", payload).then((res) => {
            store.commit(types.CREATE_WORD_GROUP, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.UPDATE_WORD_GROUP](store, payload) {
        return ajax.put(`/word-groups/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_WORD_GROUP, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },

    [types.DELETE_WORD_GROUP](store, uuid) {
        return ajax.delete(`/word-groups/${uuid}`).then((res) => {
            store.commit(types.DELETE_WORD_GROUP, uuid)
            return Promise.resolve(uuid)
        })
    },

    //PRODUCTS

    [types.CREATE_PRODUCT](store, payload) {
        return ajax.post("/products", payload).then((res) => {
            store.commit(types.CREATE_PRODUCT, res.product)
            return Promise.resolve(res.product)
        })
    },

    [types.GET_PRODUCTS](store, payload) {
        return ajax.get("/products").then((res) => {
            store.commit(types.GET_PRODUCTS, res.products)
            return Promise.resolve(res.products)
        })
    },

    [types.UPDATE_PRODUCT](store, payload) {
        return ajax.put(`/products/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_PRODUCT, res.product)
            return Promise.resolve(res.product)
        })
    },
    [types.DELETE_PRODUCT](store, uuid) {
        return ajax.delete(`/products/${uuid}`).then((res) => {
            store.commit(types.DELETE_PRODUCT, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.GET_PRODUCT_BY_UUID](store, uuid) {
        const exist = store.state.products.find((w) => w.uuid === uuid) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/products/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_PRODUCT_BY_UUID, res.product)
            return Promise.resolve(res.product)
        })
    },

    // SCHOOLS

    [types.CREATE_SCHOOL](store, payload) {
        return ajax.post("/schools", payload).then((res) => {
            store.commit(types.CREATE_SCHOOL, res.school)
            return Promise.resolve(res.school)
        })
    },
    [types.GET_SCHOOLS](store, payload) {
        return ajax.get("/schools").then((res) => {
            let schoolsById = res.schools.reduce((acc, school) => {
                acc[school.id] = school
                return acc
            }, {})
            store.commit(types.GET_SCHOOLS, schoolsById)
            return Promise.resolve(schoolsById)
        })
    },
    [types.UPDATE_SCHOOL](store, payload) {
        return ajax.put(`/schools/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_SCHOOL, res.school)
            return Promise.resolve(res.school)
        })
    },
    [types.DELETE_SCHOOL](store, uuid) {
        return ajax.delete(`/schools/${uuid}`).then((res) => {
            store.commit(types.DELETE_SCHOOL, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.GET_SCHOOL_BY_UUID](store, uuid) {
        const exist = store.state.classrooms.find((w) => w.uuid === uuid) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/schools/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_SCHOOL_BY_UUID, res.school)
            return Promise.resolve(res.school)
        })
    },

    // CLASSROOM

    [types.CREATE_CLASSROOM](store, payload) {
        return ajax.post("/classrooms", payload).then((res) => {
            store.commit(types.CREATE_CLASSROOM, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.UPDATE_CLASSROOM](store, payload) {
        return ajax.put(`/classrooms/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_CLASSROOM, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.DELETE_CLASSROOM](store, uuid) {
        return ajax.delete(`/classrooms/${uuid}`).then((res) => {
            store.commit(types.DELETE_CLASSROOM, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.GET_CLASSROOM_BY_UUID](store, uuid) {
        const exist = store.state.classrooms.find((w) => w.uuid === uuid) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/classrooms/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_CLASSROOM_BY_UUID, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.GET_CLASSROOM_BY_ID](store, id) {
        const exist = store.state.classrooms.find((w) => w.id === id) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/classrooms/id/${id}`).then((res) => {
            store.commit(types.GET_CLASSROOM_BY_ID, res.classroom)
            return Promise.resolve(res.classroom)
        })
    },
    [types.GET_CLASSROOMS](store, payload) {
        return ajax.get("/classrooms").then((res) => {
            store.commit(types.GET_CLASSROOMS, res.classrooms)
            return Promise.resolve(res.classrooms)
        })
    },

    // WORDS

    [types.CREATE_WORD](store, payload) {
        return ajax.post("/words", payload).then((res) => {
            store.commit(types.CREATE_WORD, res.word)
            return Promise.resolve(res.word)
        })
    },
    [types.UPDATE_WORD](store, payload) {
        return ajax.put(`/words/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_WORD, res.word)
            return Promise.resolve(res.word)
        })
    },
    [types.DELETE_WORD](store, uuid) {
        return ajax.delete(`/words/${uuid}`).then((res) => {
            store.commit(types.DELETE_WORD, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.GET_WORD_BY_UUID](store, uuid) {
        //dont check for exist, since uuid will return full data
        return ajax.get(`/words/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_WORD_BY_UUID, res.word)
            return Promise.resolve(res.word)
        })
    },
    [types.GET_WORDS](store, payload) {
        return ajax.get("/words").then((res) => {
            store.commit(types.GET_WORDS, res.words)
            return Promise.resolve(res.words)
        })
    },

    // STUDENTS

    [types.GET_STUDENT_BY_UUID](store, uuid) {
        const exist = store.state.students.find((w) => w.uuid === uuid) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/students/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_STUDENT_BY_UUID, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.UPDATE_STUDENT](store, payload) {
        return ajax.put(`/students/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_STUDENT, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.DELETE_STUDENT](store, uuid) {
        return ajax.delete(`/students/${uuid}`).then((res) => {
            store.commit(types.DELETE_STUDENT, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.CREATE_STUDENT](store, payload) {
        return ajax.post("/students", payload).then((res) => {
            store.commit(types.CREATE_STUDENT, res.student)
            return Promise.resolve(res.student)
        })
    },
    [types.GET_STUDENTS](store, payload) {
        return ajax.get("/students").then((res) => {
            store.commit(types.GET_STUDENTS, res.students)
            return Promise.resolve(res.students)
        })
    },

    //SCHOOL USERS
    [types.GET_SCHOOL_USER_BY_UUID](store, uuid) {
        const exist = store.state.schoolUsers.find((w) => w.uuid === uuid) || null
        if (exist) {
            return Promise.resolve(cloneDeep(exist))
        }
        return ajax.get(`/school-users/uuid/${uuid}`).then((res) => {
            store.commit(types.GET_SCHOOL_USER_BY_UUID, res.schoolUser)
            return Promise.resolve(res.schoolUser)
        })
    },
    [types.UPDATE_SCHOOL_USER](store, payload) {
        return ajax.put(`/school-users/${payload.uuid}`, payload).then((res) => {
            store.commit(types.UPDATE_SCHOOL_USER, res.schoolUser)
            return Promise.resolve(res.schoolUser)
        })
    },
    [types.DELETE_SCHOOL_USER](store, uuid) {
        return ajax.delete(`/school-users/${uuid}`).then((res) => {
            store.commit(types.DELETE_SCHOOL_USER, uuid)
            return Promise.resolve(uuid)
        })
    },
    [types.CREATE_SCHOOL_USER](store, payload) {
        return ajax.post("/school-users", payload).then((res) => {
            store.commit(types.CREATE_SCHOOL_USER, res.schoolUser)
            return Promise.resolve(res.schoolUser)
        })
    },
    [types.GET_SCHOOL_USERS](store, payload) {
        return ajax.get("/school-users").then((res) => {
            store.commit(types.GET_SCHOOL_USERS, res.schoolUsers)
            return Promise.resolve(res.schoolUsers)
        })
    },

    // OTHER

    [types.GET_UPLOADS](store, folder) {
        // stop all multiple fetch
        if (typeof store.state.uploadsByFolder[folder] !== "undefined") {
            return Promise.resolve(store.state.uploadsByFolder[folder])
        }
        store.commit(types.GET_UPLOADS, {
            folder: folder,
            wasFetch: false,
            isFetching: true,
            images: []
        })
        return ajax
            .get("/globals/uploads", {
                params: {
                    folder: folder
                }
            })
            .then((res) => {
                this.loading = false

                store.commit(types.GET_UPLOADS, {
                    folder: folder,
                    wasFetch: true,
                    isFetching: false,
                    images: res.images
                })
                return Promise.resolve(store.state.uploadsByFolder[folder])
            })
            .catch((err) => {
                store.commit(types.GET_UPLOADS, {
                    folder: folder,
                    wasFetch: true,
                    isFetching: false,
                    images: []
                })
                return Promise.reject()
            })
    },
    [types.GET_GLOBAL](store, payload) {
        return ajax.get("/globals").then((res) => {
            store.commit(types.GET_GLOBAL, res)
            return Promise.resolve(res)
        })
    },
    [types.GET_DATA](store, payload) {
        return Promise.all([store.dispatch(types.GET_GLOBAL)])
    }
}

// mutations
const mutations = {
    // WORD GROUP
    [types.CREATE_WORD_GROUP](state, newWordGroup) {
        state.wordGroups.push(newWordGroup)
    },
    [types.UPDATE_WORD_GROUP](state, newWordGroup) {
        const idx = state.wordGroups.findIndex((wg) => wg.id === newWordGroup.id)
        if (idx === -1) return
        state.wordGroups[idx] = newWordGroup
    },
    [types.GET_WORD_GROUP_BY_UUID](state, newWordGroup) {
        const idx = state.wordGroups.findIndex((wg) => wg.id === newWordGroup.id)
        if (idx !== -1) return
        state.wordGroups.push(newWordGroup)
    },
    [types.GET_WORD_GROUPS](state, wordGroups) {
        state.wordGroups = wordGroups
    },
    [types.DELETE_WORD_GROUP](state, uuid) {
        const idx = state.wordGroups.findIndex((wg) => wg.uuid === uuid)
        if (idx === -1) return
        state.wordGroups.splice(idx, 1)
    },

    // SCHOOLS
    [types.CREATE_SCHOOL](state, data) {
        state.schoolsById[data.id] = data
    },
    [types.GET_SCHOOLS](state, schoolsById) {
        state.schoolsById = schoolsById
    },
    [types.UPDATE_SCHOOL](state, newSchool) {
        state.schoolsById[newSchool.id] = newSchool
    },
    [types.GET_SCHOOL_BY_UUID](state, newSchool) {
        state.schoolsById[newSchool.id] = newSchool
    },
    [types.DELETE_SCHOOL](state, uuid) {
        const school = Object.values(state.schoolsById).find((s) => s.uuid === uuid)
        if (!school) return

        delete state.schoolsById[school.id]
    },

    // CLASSROOMS

    [types.GET_CLASSROOMS](state, classrooms) {
        state.classrooms = classrooms
    },
    [types.CREATE_CLASSROOM](state, data) {
        state.classrooms.push(data)
    },
    [types.UPDATE_CLASSROOM](state, newClassroom) {
        const idx = state.classrooms.findIndex((c) => c.id === newClassroom.id)
        if (idx === -1) return
        state.classrooms[idx] = newClassroom
    },
    [types.GET_CLASSROOM_BY_UUID](state, newClassroom) {
        const idx = state.classrooms.findIndex((c) => c.id === newClassroom.id)
        if (idx !== -1) return
        state.classrooms.push(newClassroom)
    },
    [types.GET_CLASSROOM_BY_ID](state, newClassroom) {
        const idx = state.classrooms.findIndex((c) => c.id === newClassroom.id)
        if (idx !== -1) return
        state.classrooms.push(newClassroom)
    },
    [types.DELETE_CLASSROOM](state, uuid) {
        const idx = state.classrooms.findIndex((c) => c.uuid === uuid)
        if (idx === -1) return
        state.classrooms.splice(idx, 1)
    },

    // WORDS
    [types.CREATE_WORD](state, newWord) {
        state.words.push(newWord)
    },
    [types.UPDATE_WORD](state, newWord) {
        const idx = state.words.findIndex((w) => w.id === newWord.id)
        if (idx === -1) return
        state.words[idx] = newWord
    },
    [types.DELETE_WORD](state, uuid) {
        const idx = state.words.findIndex((w) => w.uuid === uuid)
        if (idx === -1) return
        state.words.splice(idx, 1)
    },
    [types.GET_WORD_BY_UUID](state, newWord) {
        const idx = state.words.findIndex((w) => w.id === newWord.id)
        if (idx !== -1) return
        state.words.push(newWord)
    },
    [types.GET_WORDS](state, words) {
        state.words = words
    },

    //TEACHERS
    [types.CREATE_SCHOOL_USER](state, newSchoolUser) {
        state.schoolUsers.push(newSchoolUser)
    },
    [types.UPDATE_SCHOOL_USER](state, newSchoolUser) {
        const idx = state.schoolUsers.findIndex((w) => w.id === newSchoolUser.id)
        if (idx === -1) return
        state.schoolUsers[idx] = newSchoolUser
    },
    [types.DELETE_SCHOOL_USER](state, uuid) {
        const idx = state.schoolUsers.findIndex((w) => w.uuid === uuid)
        if (idx === -1) return
        state.schoolUsers.splice(idx, 1)
    },
    [types.GET_SCHOOL_USER_BY_UUID](state, newSchoolUser) {
        const idx = state.schoolUsers.findIndex((w) => w.id === newSchoolUser.id)
        if (idx !== -1) return
        state.schoolUsers.push(newSchoolUser)
    },
    [types.GET_SCHOOL_USERS](state, schoolUsers) {
        state.schoolUsers = schoolUsers
    },

    // STUDENTS
    [types.CREATE_STUDENT](state, newStudent) {
        state.students.push(newStudent)
    },
    [types.GET_STUDENT_BY_UUID](state, newStudent) {
        const idx = state.students.findIndex((s) => s.id === newStudent.id)
        if (idx !== -1) return
        state.students.push(newStudent)
    },
    [types.UPDATE_STUDENT](state, newStudent) {
        const idx = state.students.findIndex((s) => s.id === newStudent.id)
        if (idx === -1) return
        state.students[idx] = newStudent
    },
    [types.DELETE_STUDENT](state, uuid) {
        const idx = state.students.findIndex((s) => s.uuid === uuid)
        if (idx === -1) return
        state.students.splice(idx, 1)
    },
    [types.GET_STUDENTS](state, students) {
        state.students = students
    },

    //PRODUCTS
    [types.CREATE_PRODUCT](state, newProduct) {
        state.products.push(newProduct)
    },
    [types.GET_PRODUCT_BY_UUID](state, newProduct) {
        const idx = state.products.findIndex((s) => s.id === newProduct.id)
        if (idx !== -1) return
        state.products.push(newProduct)
    },
    [types.UPDATE_PRODUCT](state, newProduct) {
        const idx = state.products.findIndex((s) => s.id === newProduct.id)
        if (idx === -1) return
        state.products[idx] = newProduct
    },
    [types.DELETE_PRODUCT](state, uuid) {
        const idx = state.products.findIndex((s) => s.uuid === uuid)
        if (idx === -1) return
        state.products.splice(idx, 1)
    },
    [types.GET_PRODUCTS](state, products) {
        state.products = products
    },
    // OTHER
    [types.GET_UPLOADS](state, payload) {
        state.uploadsByFolder[payload.folder] = payload
    },
    [types.GET_GLOBAL](state, payload) {
        state.gameTypes = payload.gameTypes
        state.productCategories = payload.productCategories
    },
    [types.ADD_UPLOAD](state, imageData) {
        if (typeof state.uploadsByFolder[imageData.folder] !== "undefined") {
            state.uploadsByFolder[imageData.folder].images.unshift(imageData) // add to index 0
        }
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
