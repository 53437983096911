<template>
    <modal
        max-width="full"
        @close="$emit('close')"
        :show="true">
        <div class="flex flex-col justify-center items-center min-h-full w-full">
            <h3 class="text-center py-3 w-full">
                Ajouter de nouveaux mots automatiquement au groupe du mot "{{ wordGroup.text }}"
            </h3>
            <loading-container
                class="flex-1 w-full h-full"
                class-content="h-full"
                :is-loading="isLoading">
                <div class="h-32 w-full p-2">
                    <input
                        type="file"
                        class="hidden"
                        ref="fileInput"
                        multiple
                        accept="image/png,image/jpeg"
                        @change="updatePhoto" />
                    <div
                        class="dropzone py-2 border border-dashed bg-gray-200 border-gray-400 rounded-sm h-full w-full flex flex-col justify-center items-center"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="onDrop">
                        <loader v-if="isLoading"></loader>
                        <p
                            class="text-sm text-gray-500"
                            v-if="!isLoading">
                            Glissez déposer des images ou
                        </p>
                        <button
                            v-if="!isLoading"
                            class="btn--secondary mt-2"
                            type="button"
                            @click.prevent="selectNewPhoto">
                            Téléverser les images
                        </button>
                    </div>
                </div>

                <h5 class="pt-6">Réglages</h5>
                <div class="pb-6 w-auto form grid grid-cols-1 md:grid-cols-3">
                    <item-select
                        hide-empty
                        select
                        v-model="lang"
                        label="Langue des mots"
                        :data="Object.values(LANGS)"
                        :value-key="null"
                        :label-key="(item) => $t(`global.langs.${item}`)" />
                </div>

                <div class="w-full py-6 form">
                    <div
                        v-for="(word, idx) in words"
                        :key="idx"
                        class="flex flex-row justify-between items-center space-x-2 md:space-x-12">
                        <img
                            :src="word.imageUrl"
                            class="w-24 h-24 object-contain object-center" />

                        <div class="input-container flex-1">
                            <div class="c__input w-full">
                                <label>Nom</label>
                                <input
                                    type="text"
                                    name="word"
                                    :value="word.text"
                                    @input="changeWordText($event.target.value, idx)" />
                            </div>
                        </div>

                        <button
                            class="btn h-full ml-2"
                            type="button"
                            @click.prevent="deleteWord(idx)">
                            <icons
                                class="h-4 w-auto inline-block text-white"
                                icon="trash" />
                        </button>
                    </div>
                </div>
            </loading-container>
            <div v-if="words.length > 0">
                <h3 class="text-red-500 text-center">IMPORTANT!</h3>
                <p class="text-red-500 text-center">Veuillez vous assurer que les mots n'existent pas déjà.</p>
            </div>
            <p
                class="text-red-500"
                v-else>
                Veuillez téléverser de nouvelles images pour commencer
            </p>
            <div class="w-full mt-12 flex flex-row justify-center items-center space-x-3">
                <button
                    class="btn--text"
                    @click.prevent="$emit('close')">
                    Annuler
                </button>
                <button
                    class="btn"
                    :class="{ disabled: words.length === 0 }"
                    @click.prevent="createWords">
                    Ajouter les mots
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import LoadingContainer from "components/LoadingContainer"
import Loader from "components/globals/Loader"
import ajax from "config/ajax"
import * as types from "store/types"
import Icons from "components/globals/Icons"
import { LANGS } from "@globals/constants"
import ItemSelect from "components/forms/ItemSelect"

export default {
    components: { ItemSelect, Icons, Loader, LoadingContainer, Modal },
    emits: ["close", "created"],
    props: {
        wordGroup: Object
    },
    data() {
        return {
            LANGS,

            lang: LANGS.FR,

            isLoading: false,
            images: [],
            words: []
        }
    },
    watch: {
        lang() {
            this.$nextTick(() => {
                this.words.forEach((word, idx) => {
                    this.changeWordText(word.text, idx)
                })
            })
        }
    },
    methods: {
        updatePhoto() {
            if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                this.uploadPhotos(this.$refs.fileInput.files)
            }
        },
        async uploadPhotos(files) {
            if (files.length === 0) return

            this.isLoading = true

            await Promise.all(
                Array.from(files).map(async (file) => {
                    const formData = new FormData()
                    formData.append("folderPath", "words")
                    formData.append("file", file)

                    let data = await ajax.post("/globals/upload", formData)

                    if (!data.success) return

                    let split = file.name.split(".")
                    let text = split.length > 0 ? split[0] : ""

                    this.words.push({
                        active: true,
                        imagePath: data.path,
                        imageUrl: data.url,
                        text: text,
                        description: "",
                        rule: "",
                        traductions: Object.values(LANGS).map((lang) => ({
                            active: this.lang === lang,
                            lang: lang,
                            sentences: [],
                            questions: [],
                            imagePath: data.path,
                            imageUrl: data.url,
                            text: this.lang === lang ? text : "", //init to empty if not current lang
                            description: "",
                            rule: ""
                        }))
                    })

                    this.$store.commit(types.ADD_UPLOAD, {
                        path: data.path,
                        url: data.url,
                        folder: "words"
                    })
                })
            )

            this.isLoading = false
        },

        selectNewPhoto() {
            this.$refs.fileInput.click()
        },
        onDrop(e) {
            if (e.dataTransfer.files.length > 0) {
                this.uploadPhotos(e.dataTransfer.files)
                // only one
            }
        },
        changeWordText(value, idx) {
            this.words[idx].text = value

            //only update only specific lang selected
            this.words[idx].traductions = this.words[idx].traductions.map((tr) => {
                return {
                    ...tr,
                    text: this.lang === tr.lang ? value : "" //only update selected translations
                }
            })
        },
        deleteWord(idx) {
            this.words.splice(idx, 1)
        },
        async createWords() {
            let words = []
            await Promise.all(
                this.words.map(async (word) => {
                    let data = await this.$store.dispatch(types.CREATE_WORD, word)
                    words.push(data)
                })
            )

            this.$emit("created", words)
        }
    }
}
</script>
