import { createRouter, createWebHistory } from "vue-router"
import Dashboard from "views/Dashboard"
import Layout from "views/layouts/Layout"
import Login from "views/auth/Login"
import ForgotPassword from "views/auth/ForgotPassword"
import ChangePassword from "views/auth/ChangePassword"
import Profile from "views/auth/Profile"
import store from "store/index"
import Words from "views/words/Words"
import CreateWord from "views/words/CreateWord"
import ModifyWord from "views/words/ModifyWord"
import WordGroups from "views/word-groups/WordGroups"
import CreateWordGroup from "views/word-groups/CreateWordGroup"
import ModifyWordGroup from "views/word-groups/ModifyWordGroup"
import Classrooms from "views/classrooms/Classrooms"
import CreateClassroom from "views/classrooms/CreateClassroom"
import ModifyClassroom from "views/classrooms/ModifyClassroom"
//import Game from 'views/games/Game'
import Students from "views/students/Students"
import CreateStudent from "views/students/CreateStudent"
import ModifyStudent from "views/students/ModifyStudent"
import Products from "views/products/Products"
import CreateProduct from "views/products/CreateProduct"
import ModifyProduct from "views/products/ModifyProduct"
import Schools from "views/schools/Schools"
import CreateSchool from "views/schools/CreateSchool"
import ModifySchool from "views/schools/ModifySchool"
import SchoolUsers from "views/school-users/SchoolUsers"
import CreateSchoolUser from "views/school-users/CreateSchoolUser"
import ModifySchoolUser from "views/school-users/ModifySchoolUser"

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Layout,
        meta: {
            needLogin: true
        },
        children: [
            {
                path: "",
                redirect: "/dashboard"
            },
            {
                path: "/dashboard",
                name: "dashboard",
                component: Dashboard
            },
            {
                path: "/words",
                name: "words",
                component: Words
            },
            {
                path: "/words/create",
                name: "create-word",
                component: CreateWord
            },
            {
                path: "/words/:uuid",
                name: "modify-word",
                component: ModifyWord
            },
            {
                path: "/word-groups",
                name: "word-groups",
                component: WordGroups
            },
            {
                path: "/word-groups/create",
                name: "create-word-group",
                component: CreateWordGroup
            },
            {
                path: "/word-groups/:uuid",
                name: "modify-word-group",
                component: ModifyWordGroup
            },
            {
                path: "/classrooms",
                name: "classrooms",
                component: Classrooms
            },
            {
                path: "/classrooms/create",
                name: "create-classroom",
                component: CreateClassroom
            },
            {
                path: "/classrooms/:uuid",
                name: "modify-classroom",
                component: ModifyClassroom
            },
            {
                path: "/students",
                name: "students",
                component: Students
            },
            {
                path: "/students/create",
                name: "create-student",
                component: CreateStudent
            },
            {
                path: "/students/:uuid",
                name: "modify-student",
                component: ModifyStudent
            },

            {
                path: "/schools",
                name: "schools",
                component: Schools
            },
            {
                path: "/schools/create",
                name: "create-school",
                component: CreateSchool
            },
            {
                path: "/schools/:uuid",
                name: "modify-school",
                component: ModifySchool
            },

            {
                path: "/school-users",
                name: "school-users",
                component: SchoolUsers
            },
            {
                path: "/school-users/create",
                name: "create-school-user",
                component: CreateSchoolUser
            },
            {
                path: "/school-users/:uuid",
                name: "modify-school-user",
                component: ModifySchoolUser
            },

            {
                path: "/products",
                name: "products",
                component: Products
            },
            {
                path: "/products/create",
                name: "create-product",
                component: CreateProduct
            },
            {
                path: "/products/:uuid",
                name: "modify-product",
                component: ModifyProduct
            },

            {
                path: "/profile",
                name: "profile",
                component: Profile
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/change-pwd",
        name: "change-pwd",
        component: ChangePassword
    },
    {
        path: "/forgot-pwd",
        name: "forgot-pwd",
        component: ForgotPassword
    },
    /*{
      path: '/register',
      name: 'register',
      component: Register
    }, */
    {
        path: "/:catchAll(.*)",
        redirect: "/login"
    }
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.WEBSITE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let needLogin = false
    if (typeof to.meta.needLogin !== "undefined") {
        needLogin = to.meta.needLogin
    } else {
        to.matched.forEach((match) => {
            if (!needLogin) {
                // if one true, always needs login
                if (typeof match.meta.needLogin !== "undefined") {
                    needLogin = match.meta.needLogin
                }
            }
        })
    }
    if (needLogin && !store.state.auth.jwt) {
        return next("/login")
    }
    next()
})

export default router
