<template>
    <div
        class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        <!--
          Notification panel, show/hide based on alert state.

          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <Icons
                            icon="error-circle"
                            class="h-6 w-6 text-red-400"
                            v-if="type_ === 'error'" />
                        <Icons
                            icon="exclamation-circle"
                            class="h-6 w-6 text-yellow-400"
                            v-if="type_ === 'warning'" />
                        <Icons
                            icon="check-circle"
                            class="h-6 w-6 text-green-400"
                            v-else />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900 m-0">
                            {{ title_ }}
                        </p>
                        <p class="text-sm text-gray-500 m-0 pt-1">
                            {{ message_ }}
                        </p>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                        <button
                            type="button"
                            @click.prevent="closeFlash"
                            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Close</span>
                            <Icons
                                icon="cross"
                                class="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus, { EVENTS } from "@/eventbus"
import gsap from "gsap"
import Icons from "components/globals/Icons"

const DEFAULT_PROPS = {
    title: "Nouveau message",
    message: "Vous avez un message",
    type: "success", // success, warning, error
    time: 4000 // 5seconds
}

export default {
    name: "flash",
    components: { Icons },
    created() {
        this.showFlash = this.showFlash.bind(this)
        this.hideFlash = this.hideFlash.bind(this)
        EventBus.on(EVENTS.SHOW_FLASH, this.showFlash)
        EventBus.on(EVENTS.HIDE_FLASH, this.hideFlash)

        this.id = -1
    },
    data() {
        return {
            isVisible: false,
            title_: DEFAULT_PROPS.title,
            message_: DEFAULT_PROPS.message,
            type_: DEFAULT_PROPS.type,
            time_: DEFAULT_PROPS.time
        }
    },
    mounted() {
        gsap.set(this.$el, { xPercent: 100 })
    },
    beforeUnmount() {
        EventBus.off(EVENTS.SHOW_FLASH, this.showFlash)
        EventBus.off(EVENTS.HIDE_FLASH, this.hideFlash)
    },
    props: {
        title: {
            type: String,
            default: DEFAULT_PROPS.title
        },
        message: {
            type: String,
            default: DEFAULT_PROPS.message
        },
        type: {
            type: String,
            default: DEFAULT_PROPS.type
        },
        time: {
            // in MS
            type: Number,
            default: DEFAULT_PROPS.time
        }
    },
    methods: {
        showFlash(props = {}) {
            if (this.isVisible) {
                // hide the flash and call again
                this.hideFlash().then(() => {
                    // make sure the data is change, so we escape a infinite loop
                    this.isVisible = false

                    this.showFlash(props)
                })
                return
            }
            clearTimeout(this.id)

            // set props
            this.title_ = props.title ? props.title : DEFAULT_PROPS.title
            this.message_ = props.message ? props.message : DEFAULT_PROPS.message
            this.type_ = props.color ? props.color : DEFAULT_PROPS.type
            this.time_ = props.time ? props.time : DEFAULT_PROPS.time

            this.isVisible = true
            gsap.to(this.$el, 0.75, { xPercent: 0 })

            // call hide in
            this.id = setTimeout(this.hideFlash, this.time_)
        },
        closeFlash() {
            this.hideFlash()
        },
        hideFlash() {
            return new Promise((resolve, reject) => {
                gsap.to(this.$el, 0.75, {
                    xPercent: 100,
                    onComplete: () => {
                        this.isVisible = false
                        this.$nextTick(() => {
                            resolve()
                        })
                    }
                })
            })
        }
    }
}
</script>

<style lang="scss"></style>
