import * as types from "store/types"
import ajax from "config/ajax"

// Static

// initial state, with craft backend or empty
const state = {
    clicks: [],
    timePasts: [],
    topGameTypes: []
}

// getters, make function easy to access by vue
const getters = {}

// actions
const actions = {
    [types.GET_STATS](store, payload) {
        return ajax
            .get(`/stats`, {
                params: payload
            })
            .then((res) => {
                return res.stats
            })
    },
    [types.GET_STATS_CLICKS](store, payload) {
        if (store.state.clicks.length > 0) {
            return Promise.resolve(store.clicks)
        }
        return ajax.get(`/stats/clicks`).then((res) => {
            store.commit(types.GET_STATS_CLICKS, res.clicks)
            return res.clicks
        })
    },
    [types.GET_TIME_PASTS](store, payload) {
        if (store.state.timePasts.length > 0) {
            return Promise.resolve(store.timePasts)
        }
        return ajax.get(`/stats/time-pasts`).then((res) => {
            store.commit(types.GET_TIME_PASTS, res.timePasts)
            return res.timePasts
        })
    },

    [types.GET_TOP_GAME_TYPES](store, payload) {
        if (store.state.topGameTypes.length > 0) {
            return Promise.resolve(store.topGameTypes)
        }
        return ajax.get(`/stats/top-game-types`).then((res) => {
            store.commit(types.GET_TOP_GAME_TYPES, res.gameTypes)
            return res.gameTypes
        })
    }
}

// mutations
const mutations = {
    [types.GET_STATS_CLICKS](state, clicks) {
        state.clicks = clicks
    },
    [types.GET_TIME_PASTS](state, timePasts) {
        state.timePasts = timePasts
    },
    [types.GET_TOP_GAME_TYPES](state, topGameTypes) {
        state.topGameTypes = topGameTypes
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
