<template>
    <div id="app">
        <div
            v-if="isLoading"
            class="min-w-screen min-h-screen flex flex-col justify-center items-center">
            <loader></loader>
        </div>

        <main v-if="!isLoading">
            <router-view />
        </main>
        <div id="modal" />
    </div>
</template>
<script>
import * as types from "store/types"
import Loader from "components/globals/Loader"
import Flash from "components/Flash"

export default {
    components: {
        Flash,
        Loader
    },
    data() {
        return {
            isLoading: true
        }
    },
    created() {
        this.setUser()
    },
    methods: {
        setUser() {
            const existingToken = window.localStorage.getItem("kwordz:jwt")
            if (!existingToken) {
                this.isLoading = false
                return
            }
            this.$store.commit(types.JWT, existingToken)
            this.$store
                .dispatch(types.GET_USER)
                .then((res) => {
                    if (this.$router.currentRoute.name === "login") {
                        this.$router.replace("/")
                    }
                    this.isLoading = false

                    this.$store.dispatch(types.GET_DATA)
                })
                .catch((err) => {
                    this.$store.commit(types.LOGOUT)
                    this.$router.replace("/login")
                    this.isLoading = false
                })
        }
    }
}
</script>
