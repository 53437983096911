<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/products"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux produits
            </router-link>
            <h1 class="pb-6">Modifier ce produit</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="product">
                    <product-form
                        :product="product"
                        :errors="errors" />
                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer le produit
                        </button>
                        <button
                            type="submit"
                            class="btn">
                            Modifier
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import ProductForm from "views/products/ProductForm"
import { LANGS } from "@globals/constants"

export default {
    name: "modify-product",
    components: {
        ProductForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            product: null,
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.isLoading = true
        this.$store
            .dispatch(types.GET_PRODUCT_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.product = res

                //add missing langs
                Object.values(LANGS).forEach((lang) => {
                    let idx = this.product.traductions.findIndex((t) => t.lang === lang)
                    if (idx !== -1) return
                    this.product.traductions.push({
                        lang: lang,
                        imagePath: null,
                        imageUrl: null,
                        text: ""
                    })
                })

                this.isLoading = false
            })
            .catch((err) => {
                console.log(err)
                this.isLoading = false
                this.$router.replace("/products")
            })
    },
    methods: {
        remove() {
            if (!window.confirm("Effacer ce produit. Êtes-vous sûr de vouloir continuer ?")) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_PRODUCT, this.product.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/products")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true
            this.$store
                .dispatch(types.UPDATE_PRODUCT, this.product)
                .then((res) => {
                    this.product = res

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "Le produit a été enregistré",
                        title: "Produit",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
