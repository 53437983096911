<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/students"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux élèves
            </router-link>
            <h1 class="pb-6">Modifier cette élève</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="student">
                    <student-form
                        :student="student"
                        :errors="errors" />
                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer l'élève
                        </button>
                        <button
                            type="submit"
                            class="btn">
                            Modifier
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import StudentForm from "views/students/StudentForm"

export default {
    name: "modify-student",
    components: {
        StudentForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            student: null,
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.isLoading = true
        this.$store
            .dispatch(types.GET_STUDENT_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.student = res

                this.isLoading = false
            })
            .catch((err) => {
                this.isLoading = false
                this.$router.replace("/students")
            })
    },
    methods: {
        remove() {
            if (!window.confirm("Effacer cet éleve. Êtes-vous sûr de vouloir continuer ?")) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_STUDENT, this.student.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/students")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true
            this.$store
                .dispatch(types.UPDATE_STUDENT, this.student)
                .then((res) => {
                    this.student = res
                    this.student.teacherId = this.student.teacherId === null ? "" : this.student.teacherId
                    this.student.schoolId = this.student.schoolId === null ? "" : this.student.schoolId

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "L'élève a été enregistré",
                        title: "Élève",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
