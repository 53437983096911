<template>
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="mx-auto h-12 w-auto"
                :src="logo"
                alt="Workflow" />
            <p class="text-center pt-3">Admin</p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <slot></slot>
            </div>
        </div>
        <flash />
    </div>
</template>
<script>
import logo from "@globals/assets/logos/logo.svg"
import Flash from "components/Flash"

export default {
    name: "auth-layout",
    components: { Flash },
    data() {
        return {
            logo
        }
    }
}
</script>
