<template>
    <div class="">
        <div class="input-container">
            <label>Titre</label>
            <div class="c__input">
                <input
                    type="text"
                    name="title"
                    v-model="classroom.title" />
            </div>
            <p
                class="error"
                v-if="errors.title">
                {{ errors.title }}
            </p>
        </div>

        <item-select
            select
            v-model="classroom.grade"
            :data="Object.values(GRADES)"
            label="Année"
            :error="errors.grade"
            :value-key="null"
            :label-key="(item) => item" />

        <item-select
            @search="onSchoolSearch"
            v-model="classroom.schoolId"
            :data="schools"
            label="École"
            label-key="name"
            value-key="id"
            :error="errors.schoolId" />

        <item-select
            @search="onSchoolUserSearch"
            v-model="classroom.teacherId"
            :error="errors.teacherId"
            label="Professeur (selon l'école)"
            :data="schoolUsers"
            :label-key="(item) => `${item.name} (${item.email})`"
            value-key="id" />

        <items-select
            :empty-search="false"
            @search="onStudentSelect"
            v-model="classroom.students"
            :error="errors.students"
            label="Élèves (selon l'école)"
            :data="students"
            label-key="name"
            value-key="id" />
    </div>
</template>

<script>
import ItemsSelect from "components/forms/ItemsSelect"
import ItemSelect from "components/forms/ItemSelect"
import ajax from "config/ajax"
import { GRADES, USER_ROLES } from "@globals/constants"

export default {
    name: "classroom-form",
    components: {
        ItemSelect,
        ItemsSelect
    },

    props: {
        classroom: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            GRADES,
            studentIdCount: 0,

            showTeacherModal: false,
            schools: [],
            schoolUsers: [],
            students: []
        }
    },
    created() {
        if (this.classroom.schoolId) {
            this.onSchoolSearch("")
        }
        if (this.classroom.teacherId) {
            this.onSchoolUserSearch("")
        }
    },
    computed: {
        teacher() {
            return this.schoolUsers.find((t) => t.id === this.classroom.teacherId)
        }
    },
    watch: {
        "classroom.schoolId"() {
            this.$nextTick(() => {
                this.classroom.teacherId = null
                this.onSchoolUserSearch()
            })
        }
    },
    methods: {
        onStudentSelect(searchValue) {
            ajax.get(`/students/search`, {
                params: {
                    search: searchValue,
                    ...(this.classroom.schoolId
                        ? {
                              schoolId: this.classroom.schoolId
                          }
                        : {})
                }
            }).then((res) => {
                this.students = res.students
            })
        },
        onSchoolUserSearch(searchValue) {
            ajax.get(`/school-users/search`, {
                params: {
                    role: USER_ROLES.TEACHER,
                    search: searchValue,
                    ...(this.classroom.schoolId
                        ? {
                              schoolId: this.classroom.schoolId
                          }
                        : {})
                }
            }).then((res) => {
                this.schoolUsers = res.schoolUsers
            })
        },
        onSchoolSearch(searchValue) {
            ajax.get(`/schools/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.schools = res.schools
            })
        }
    }
}
</script>
