<template>
    <div class="pagination">
        <div class="container">
            <div class="flex flex-row justify-between items-center pt-6">
                <div>
                    <h1>{{ title }}</h1>
                    <p
                        class="text-sm"
                        v-if="description">
                        {{ description }}
                    </p>
                </div>
                <div class="flex flex-row justify-between items-center space-x-6">
                    <!--  <a @click.prevent="print" class="btn--outline">Imprimer</a> -->
                    <router-link
                        :to="createUrl"
                        class="btn"
                        >{{ createTitle }}
                    </router-link>
                </div>
            </div>
            <form
                class="w-full pt-2"
                @submit.prevent="search">
                <div class="w-full flex flex-row justify-start space-x-3 items-stretch">
                    <div class="c__input flex-1 mt-0">
                        <input
                            class="w-full"
                            placeholder="Recherche"
                            type="text"
                            name="searchValue"
                            min="4"
                            max="191"
                            v-model="searchValue" />
                    </div>
                    <button
                        class="btn"
                        type="button"
                        @click.prevent="search">
                        <Icons
                            icon="search"
                            class="text-white w-4 h-4" />
                    </button>
                </div>
                <slot name="form"></slot>
            </form>
            <loading-container :is-loading="isLoading">
                <div class="flex flex-col mt-6">
                    <div class="overflow-x-auto">
                        <div class="align-middle inline-block min-w-full">
                            <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <slot name="header"></slot>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <slot
                                            name="data"
                                            :currentPage="currentPage"></slot>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        @goToPage="goToPage"
                        :total-results="data.length"
                        :current-page="currentPageIdx + 1"
                        :pages-count="dataByPage.length" />
                </div>
            </loading-container>
        </div>
    </div>
</template>

<script>
import { chunk } from "lodash"
import Pagination from "components/globals/Pagination"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"

export default {
    name: "pagination-layout",
    emits: ["search"],
    components: {
        Icons,
        LoadingContainer,
        Pagination
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false
        },
        createUrl: {
            type: String,
            required: true
        },
        createTitle: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            searchValue: "",

            isLoading: false,
            currentPageIdx: 0,
            itemsPerPage: 50
        }
    },
    created() {
        this.search()
    },
    watch: {
        searchValue(nextValue) {
            if (nextValue === "") {
                this.$nextTick(() => {
                    this.search()
                })
            }
        }
    },
    computed: {
        currentPage() {
            return this.dataByPage[this.currentPageIdx]
        },
        dataByPage() {
            return chunk(this.data, this.itemsPerPage)
        }
    },
    methods: {
        search() {
            this.$emit("search", this.searchValue) //init
            this.currentPageIdx = 0 //reset pagination when searching
        },
        goToPage(idx) {
            this.currentPageIdx = idx
        }
    }
}
</script>
