import mitt from "mitt"

export const EVENTS = {
    SHOW_FLASH: "SHOW_FLASH",
    HIDE_FLASH: "HIDE_FLASH"
}

const EventBus = mitt()

export default EventBus
