<template>
    <auth-layout>
        <form
            @submit.prevent="login"
            @keyup.enter.prevent="login">
            <div class="input-container">
                <label
                    for="email"
                    class="">
                    Adresse courriel
                </label>
                <div class="c__input">
                    <input
                        id="email"
                        type="email"
                        required
                        v-model="email"
                        name="email" />
                </div>
                <p
                    class="error"
                    v-if="errors.email">
                    {{ errors.email }}
                </p>
            </div>

            <div class="input-container">
                <label
                    for="password"
                    class="">
                    Mot de passe
                </label>
                <div class="c__input">
                    <input
                        id="password"
                        type="password"
                        name="password"
                        v-model="password"
                        required />
                </div>
                <p
                    class="error"
                    v-if="errors.password">
                    {{ errors.password }}
                </p>
            </div>

            <div class="mt-6 flex items-center justify-end">
                <router-link
                    to="forgot-pwd"
                    class="btn--text">
                    Mot de passe oublié?
                </router-link>
            </div>

            <div class="mt-6">
                <button
                    type="submit"
                    class="btn w-full">
                    Se connecter
                </button>
            </div>
        </form>
    </auth-layout>
</template>

<script>
import * as types from "store/types"
import AuthLayout from "views/layouts/AuthLayout"

export default {
    name: "Login",
    components: { AuthLayout },
    data() {
        return {
            email: "",
            password: "",
            errors: {}
        }
    },
    methods: {
        login() {
            this.errors = {}
            this.$store
                .dispatch(types.LOGIN, {
                    email: this.email,
                    password: this.password
                })
                .then(() => {
                    this.$router.push("/")
                })
                .catch((err) => {
                    console.log(err)
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
