<template>
    <div class="products">
        <pagination-layout
            title="Produits"
            create-title="Créer un nouveau produit"
            create-url="/products/create"
            @search="onSearch"
            :data="products">
            <template v-slot:header>
                <custom-th> Nom </custom-th>
                <custom-th> Catégorie </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="product in currentPage"
                    :key="product.id">
                    <custom-td>
                        {{ product.text }}
                    </custom-td>
                    <custom-td>
                        {{ getCategoryName(product.productCategoryId) }}
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/products/${product.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTd from "components/tables/CustomTd"
import CustomTh from "components/tables/CustomTh"
import ajax from "config/ajax"

export default {
    name: "products",
    components: {
        CustomTh,
        PaginationLayout,
        CustomTd
    },
    data() {
        return {
            products: []
        }
    },
    methods: {
        onSearch(searchValue) {
            ajax.get(`/products/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.products = res.products
            })
        },
        getCategoryName(id) {
            let productCategory = this.$store.state.data.productCategories.find((pC) => pC.id === id)
            if (productCategory) {
                return productCategory.title
            }
            return ""
        }
    }
}
</script>
