<template>
    <div class="c__upload-image">
        <div
            class="w-full"
            :class="imageContainerClass"
            v-if="!showUploads && imageUrl">
            <div
                class="relative"
                :class="previewClass">
                <img
                    :src="imageUrl"
                    class="block object-cover w-full" />

                <div
                    class="border border-gray-300 border-solid absolute inset-0 opacity-0 hover:opacity-100 flex flex-row justify-center items-center transition duration-200 ease-in-out">
                    <button
                        type="button"
                        @click.prevent="deletePhoto"
                        class="w-8 h-8 bg-white shadow rounded-full cursor-pointer">
                        <Icons
                            icon="trash"
                            class="mx-auto block w-4 h-4 pointer-events-none"></Icons>
                    </button>
                </div>
            </div>
        </div>
        <div
            v-else
            :class="previewClass"
            class="flex flex-row justify-center items-center bg-gray-200 border border-solid border-gray-400 hover:bg-gray-300 transition duration-200 cursor-pointer"
            @click.prevent="modalOpen = true">
            <Icons
                icon="upload"
                class="block w-6 h-6 text-gray-800" />
        </div>
        <modal
            v-if="modalOpen"
            :show="modalOpen"
            @close="modalOpen = false">
            <div class="flex flex-row flex-wrap justify-start items-stretch h-full">
                <div class="w-full sm:w-2/5 p-2">
                    <input
                        type="file"
                        class="hidden"
                        ref="fileInput"
                        accept="image/png,image/jpeg"
                        @change="updatePhoto" />
                    <div
                        class="dropzone py-2 border border-dashed bg-gray-200 border-gray-400 rounded-sm h-full w-full flex flex-col justify-center items-center"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="onDrop">
                        <loader v-if="loading && showLoader"></loader>
                        <p
                            class="text-sm text-gray-500"
                            v-if="!loading">
                            Glissez déposer ou
                        </p>
                        <button
                            v-if="!loading"
                            class="btn--secondary mt-2"
                            type="button"
                            @click.prevent="selectNewPhoto">
                            Téléverser
                        </button>
                    </div>
                </div>
                <div class="w-full sm:w-3/5 flex flex-col justify-center items-center max-h-full">
                    <div class="overflow-y-auto overflow-x-hidden h-px flex-1 w-full">
                        <uploads-gallery
                            :folder="fileFolder"
                            @select="onSelect"
                            :selectedPath="imagePath"></uploads-gallery>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Loader from "components/globals/Loader"
import UploadsGallery from "components/uploads/UploadsGallery"
import * as types from "store/types"
import Modal from "components/globals/Modal"
import Icons from "components/globals/Icons"
import ajax from "config/ajax"

export default {
    name: "upload-image",
    components: {
        Modal,
        UploadsGallery,
        Loader,
        Icons
    },
    props: {
        showLoader: {
            type: Boolean,
            required: false,
            default: () => true
        },
        path: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: false
        },
        fileFolder: {
            type: String,
            default: null
        },
        previewClass: {
            type: String,
            default: "min-h-20 w-full"
        },
        imageContainerClass: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            modalOpen: false,
            imagePath: null,
            imageUrl: null,
            loading: false,
            showUploads: false
        }
    },
    created() {
        if (this.path) {
            this.imagePath = this.path
        }

        if (this.url) {
            this.imageUrl = this.url
        }
    },
    watch: {
        path(newValue, oldValue) {
            if (newValue === oldValue) return
            if (!newValue) return
            this.imagePath = newValue
        },
        url(newValue, oldValue) {
            if (newValue === oldValue) return
            if (!newValue) return
            this.imageUrl = newValue
        }
    },
    methods: {
        onSelect(data) {
            this.imageUrl = data.url
            this.imagePath = data.path

            this.$emit("upload", {
                url: data.url,
                path: data.path
            })

            this.modalOpen = false
        },
        updatePhoto() {
            if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                this.uploadPhoto(this.$refs.fileInput.files[0])
            }
        },
        uploadPhoto(file) {
            if (!file) return
            const data = new FormData()
            if (this.fileFolder) {
                data.append("folderPath", this.fileFolder)
            }
            data.append("file", file)

            this.loading = true
            ajax.post("/globals/upload", data).then((data) => {
                this.loading = false
                if (!data.success) return

                this.imageUrl = data.url
                this.imagePath = data.path

                this.modalOpen = false

                this.$store.commit(types.ADD_UPLOAD, {
                    path: data.path,
                    url: data.url,
                    folder: this.fileFolder
                })

                this.$emit("upload", {
                    url: data.url,
                    path: data.path
                })
            })
        },

        selectNewPhoto() {
            this.$refs.fileInput.click()
        },
        onDrop(e) {
            if (e.dataTransfer.files.length > 0) {
                this.uploadPhoto(e.dataTransfer.files[0])
                // only one
            }
        },
        deletePhoto() {
            this.imageUrl = null
            this.imagePath = null

            this.$emit("upload", {
                url: null,
                path: null
            })
        }
    }
}
</script>
