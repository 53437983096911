<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/school-users"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux utilisateurs
            </router-link>
            <h1 class="pb-6">Modifier cet utilisateur</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="schoolUser">
                    <school-user-form
                        @cancel-subscription="cancelSubscription"
                        @give-subscription="giveSubscription"
                        :school-user="schoolUser"
                        :errors="errors" />
                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer l'utilisateur
                        </button>
                        <button
                            type="submit"
                            class="btn">
                            Modifier
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import SchoolUserForm from "views/school-users/SchoolUserForm"
import { USER_ROLES } from "@globals/constants"
import ajax from "config/ajax"

export default {
    name: "modify-school-user",
    components: {
        SchoolUserForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            schoolUser: null,
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.isLoading = true

        this.$store
            .dispatch(types.GET_SCHOOL_USER_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.schoolUser = res

                this.isLoading = false
            })
            .catch((err) => {
                this.isLoading = false
                this.$router.replace("/school-users")
            })
    },
    methods: {
        cancelSubscription() {
            if (!window.confirm("Êtes-vous sûr de vouloir annuler le forfait ?")) {
                return
            }

            this.isLoading = true
            this.errors = {}
            ajax.delete(`/school-users/${this.schoolUser.uuid}/subscription`)
                .then((res) => {
                    this.isLoading = false
                    window.location.reload()
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        giveSubscription() {
            if (!window.confirm("Êtes-vous sûr de vouloir lui donner un forfait ?")) {
                return
            }

            this.isLoading = true
            this.errors = {}
            ajax.post(`/school-users/${this.schoolUser.uuid}/subscription`)
                .then((res) => {
                    this.isLoading = false
                    window.location.reload()
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        remove() {
            if (!window.confirm("Effacer cet utilisateur. Êtes-vous sûr de vouloir continuer ?")) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_SCHOOL_USER, this.schoolUser.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/school-users")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true
            this.$store
                .dispatch(types.UPDATE_SCHOOL_USER, this.schoolUser)
                .then((res) => {
                    this.schoolUser = res

                    this.schoolUser.schoolId = this.schoolUser.schoolId === null ? "" : this.schoolUser.schoolId

                    let mes = "Le professeur a été enregistré"
                    if (this.schoolUser.role === USER_ROLES.SCHOOL_MANAGER) {
                        mes = "Le manager a été enregistré"
                    }

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: mes,
                        title: "Professeur",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
