<template>
    <modal
        @close="$emit('close')"
        :show="true">
        <template v-slot:head>
            <form
                class="w-full flex flex-row justify-start space-x-3 items-stretch py-2"
                @submit.prevent="$emit('search', searchValue)">
                <div class="c__input flex-1 mt-0">
                    <input
                        class="w-full"
                        placeholder="Recherche"
                        type="text"
                        name="searchValue"
                        min="4"
                        max="191"
                        v-model="searchValue" />
                </div>
                <button
                    class="btn"
                    type="button"
                    @click.prevent="$emit('search', searchValue)">
                    <Icons
                        icon="search"
                        class="text-white w-4 h-4" />
                </button>
            </form>
        </template>
        <div class="flex flex-col justify-start items-start space-y-2 py-6 h-full overflow-y-auto">
            <slot class="w-full"></slot>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import Icons from "components/globals/Icons"

export default {
    components: { Icons, Modal },
    name: "SearchModal",
    emits: ["search", "close"],
    props: {
        emptySearch: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            searchValue: ""
        }
    },
    watch: {
        searchValue(nextValue) {
            if (nextValue === "" && this.emptySearch) {
                this.$emit("search", this.searchValue) //empty search
            }
        }
    },
    created() {
        if (this.emptySearch) {
            this.$emit("search", this.searchValue) //init
        }
    }
}
</script>
