<template>
    <teleport to="#modal">
        <transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed z-50 inset-0 max-h-screen p-4 flex flex-col justify-center items-center">
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div
                        v-show="show"
                        class="fixed inset-0 transform transition-all"
                        @click="close">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                </transition>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        v-show="show"
                        class="bg-white rounded-lg shadow-xl transform transition-all w-full p-4 relative h-5/6 flex flex-col justify-start items-start"
                        :class="maxWidthClass">
                        <div class="absolute right-0 top-0 -m-4">
                            <button
                                type="button"
                                @click.prevent="close"
                                class="flex flex-row justify-center items-center w-8 h-8 bg-white shadow-xl rounded-full cursor-pointer focus:outline-none">
                                <Icons
                                    icon="cross"
                                    class="block w-4 h-4 pointer-events-none"></Icons>
                            </button>
                        </div>

                        <slot
                            class="w-full"
                            name="head" />

                        <div class="overflow-y-auto flex-1 w-full">
                            <slot></slot>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>

<script>
import Icons from "components/globals/Icons"

export default {
    name: "modal",
    emits: ["close"],
    props: {
        show: {
            default: false
        },
        maxWidth: {
            default: "2xl"
        },
        closeable: {
            default: true
        }
    },
    components: {
        Icons
    },

    created() {
        document.addEventListener("keydown", this.closeOnEscape)
    },

    beforeUnmount() {
        document.removeEventListener("keydown", this.closeOnEscape)
        document.body.style.overflow = null
    },

    methods: {
        closeOnEscape(e) {
            if (e.key === "Escape" && this.show) {
                this.close()
            }
        },
        close() {
            if (this.closeable) {
                this.$emit("close")
            }
        }
    },

    watch: {
        show: {
            immediate: true,
            handler: (show) => {
                if (show) {
                    document.body.style.overflow = "hidden"
                } else {
                    document.body.style.overflow = null
                }
            }
        }
    },

    computed: {
        maxWidthClass() {
            return {
                sm: "sm:max-w-sm",
                md: "sm:max-w-md",
                lg: "sm:max-w-lg",
                xl: "sm:max-w-xl",
                "2xl": "sm:max-w-2xl",
                full: "w-full"
            }[this.maxWidth]
        }
    }
}
</script>
