<template>
    <div v-if="$store.state.auth.user">
        <div
            class="fixed inset-0 flex z-40 md:hidden"
            role="dialog"
            aria-modal="true"
            v-if="mobileMenuOpen">
            <div
                class="fixed inset-0 bg-gray-600 bg-opacity-75"
                aria-hidden="true"></div>
            <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                        type="button"
                        @click="closeMenu"
                        class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span class="sr-only">Close sidebar</span>
                        <!-- Heroicon name: outline/x -->
                        <svg
                            class="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div class="flex-shrink-0 flex items-center px-4">
                        <a href="/">
                            <img
                                class="h-8 w-auto"
                                :src="logo"
                                alt="Kwordz logo" />
                        </a>
                    </div>
                    <nav class="mt-5 px-2 space-y-1">
                        <MenuItem
                            v-for="item in menus"
                            :key="item.url"
                            :is-active="activeUrl.includes(item.url)"
                            :icon-name="item.icon"
                            :href="item.url">
                            {{ item.name }}
                        </MenuItem>
                    </nav>
                </div>
                <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
                    <a
                        href="#"
                        class="flex-shrink-0 group block">
                        <div class="flex items-center">
                            <div>
                                <img
                                    :src="avatar"
                                    class="inline-block h-9 w-9 rounded-full" />
                            </div>
                            <div class="ml-3">
                                <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                    {{ $store.state.auth.user.name }}
                                </p>
                                <a
                                    class="text-xs font-medium text-gray-500 group-hover:text-gray-700"
                                    @click.prevent="logout">
                                    {{ $t("global.logout") }}}
                                </a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="flex-shrink-0 w-14">
                <!-- Force sidebar to shrink to fit close icon -->
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
                <div
                    v-if="env !== 'production'"
                    class="w-full bg-red-logo py-1 px-2 text-white text-sm font-semibold">
                    {{ env }}
                </div>
                <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div class="flex items-center flex-shrink-0 px-4">
                        <a href="/">
                            <img
                                class="h-8 w-auto"
                                :src="logo"
                                alt="Kwordz logo" />
                        </a>
                    </div>
                    <nav class="mt-5 flex-1 px-2 bg-white space-y-1">
                        <div
                            class="flex flex-row justify-start items-center w-full"
                            v-for="item in menus"
                            :key="item.url">
                            <div
                                v-if="item.children"
                                class="w-full">
                                <div
                                    class="flex flex-row justify-start items-center w-full cursor-pointer"
                                    @click="toggleMenuItem(item.key)">
                                    <MenuItem
                                        :icon-name="item.icon"
                                        href="#">
                                        <div class="flex flex-row justify-between items-center w-full">
                                            {{ item.name }}
                                            <Icons
                                                icon="caret"
                                                class="w-4 h-4"
                                                :class="`${
                                                    menusOpen.includes(item.key) ? 'transform rotate-180' : ''
                                                }`" />
                                        </div>
                                    </MenuItem>
                                </div>
                                <div
                                    v-if="menusOpen.includes(item.key)"
                                    class="pl-2 pt-2">
                                    <MenuItem
                                        :is-active="activeUrl.includes(child.url)"
                                        :icon-name="child.icon"
                                        v-for="child in item.children"
                                        :key="child.key"
                                        :href="child.url">
                                        {{ child.name }}
                                    </MenuItem>
                                </div>
                            </div>
                            <div
                                v-else
                                class="w-full">
                                <MenuItem
                                    :is-active="activeUrl.includes(item.url)"
                                    :icon-name="item.icon"
                                    :href="item.url">
                                    {{ item.name }}
                                </MenuItem>
                            </div>
                        </div>
                    </nav>
                </div>
                <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
                    <a
                        href="#"
                        class="flex-shrink-0 w-full group block">
                        <div class="flex items-center">
                            <div>
                                <img
                                    class="inline-block h-9 w-9 rounded-full"
                                    v-if="$store.state.auth.user && $store.state.auth.user.picturePath"
                                    :src="$store.state.auth.user.pictureUrl"
                                    alt="" />
                                <img
                                    class="inline-block h-9 w-9 rounded-full border border-gray-700"
                                    v-else
                                    :src="`https://api.dicebear.com/7.x/initials/svg?seed=${encodeURI(
                                        $store.state.auth.user.name
                                    )}`"
                                    alt="" />
                            </div>
                            <div class="ml-3">
                                <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                    {{ $store.state.auth.user.name }}
                                </p>
                                <a
                                    class="text-xs font-medium text-gray-500 group-hover:text-gray-700"
                                    @click="logout">
                                    Se déconnecter
                                </a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="md:pl-64 flex flex-col w-full">
            <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                <button
                    type="button"
                    @click="openMenu"
                    class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span class="sr-only">Open sidebar</span>
                    <!-- Heroicon name: outline/menu -->
                    <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
            <main class="flex-1">
                <div class="py-6 md:py-0">
                    <div>
                        <!-- <header class="bg-white shadow">
               <div
                   class="container mx-auto px-4 md:px-2 mx-auto py-6 flex flex-row justify-between items-center">
                 <slot name="header"/>
               </div>
             </header> -->
                        <main class="w-full mx-auto px-4 sm:px-6 lg:px-0 relative">
                            <div class="page_content">
                                <router-view />
                                <div class="h-12 mt-12 w-full">
                                    <div
                                        class="w-full z-10 w-full absolute left-0 right-0 bottom-0 flex flex-col justify-center items-center">
                                        <flash />
                                        <footer
                                            class="w-full bg-gray-200 h-12 flex flex-row justify-center items-center relative z-2">
                                            <p class="text-center text-black text-sm">
                                                © {{ year }} Kwordz Inc. Tous droits réservés.
                                            </p>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
<script>
import logo from "@globals/assets/logos/logo.svg"
import * as types from "store/types"
import MenuItem from "components/globals/MenuItem"
import Icons from "components/globals/Icons"
import Flash from "components/Flash"

export default {
    name: "layout",
    components: { Flash, Icons, MenuItem },
    data() {
        return {
            logo: logo,
            mobileMenuOpen: false,
            year: new Date().getFullYear(),
            env: process.env.NODE_ENV,
            menusOpen: [],

            menus: [
                {
                    name: "Tableau de bord",
                    key: "dashboard",
                    icon: "stats",
                    url: "/dashboard"
                },
                {
                    name: "Écoles",
                    key: "school",
                    icon: "",
                    children: [
                        {
                            name: "Classes",
                            key: "classrooms",
                            icon: "",
                            url: "/classrooms"
                        },
                        {
                            name: "Élèves",
                            key: "students",
                            icon: "",
                            url: "/students"
                        },
                        {
                            name: "Professeurs/Manager",
                            key: "school-users",
                            icon: "",
                            url: "/school-users"
                        },
                        {
                            name: "Écoles",
                            key: "buildings",
                            icon: "",
                            url: "/schools"
                        }
                    ]
                },
                {
                    name: "Mots",
                    key: "words",
                    icon: "",
                    children: [
                        {
                            name: "Liste de mots",
                            key: "word-groups",
                            icon: "",
                            url: "/word-groups"
                        },
                        {
                            name: "Mots",
                            key: "words",
                            icon: "",
                            url: "/words"
                        }
                    ]
                },
                {
                    name: "Produits",
                    key: "products",
                    icon: "",
                    url: "/products"
                }
            ]
        }
    },
    created() {
        let currentActive = null
        this.menus
            .filter((m) => m.children)
            .forEach((m) => {
                let isActive = this.getParentIsActive(m.key)
                if (isActive) {
                    this.menusOpen.push(m.key)
                }
            })

        if (!this.$store.state.auth.user) {
            this.$router.replace("/")
        }
    },
    computed: {
        activeUrl() {
            return this.$route.path
        }
    },
    methods: {
        toggleMenuItem(key) {
            let idx = this.menusOpen.indexOf(key)
            if (idx === -1) {
                this.menusOpen.push(key)
            } else {
                this.menusOpen.splice(idx, 1)
            }
        },
        getParentIsActive(key) {
            let el = this.menus.find((m) => m.key === key)
            if (!el) return false
            if (typeof el.children === "undefined") {
                return this.activeUrl.includes(el.url)
            }
            return el.children.filter((c) => this.activeUrl.includes(c.url)).length > 0
        },
        openMenu() {
            this.mobileMenuOpen = true
        },
        closeMenu() {
            this.mobileMenuOpen = false
        },
        logout() {
            this.$store.commit(types.LOGOUT)
            this.$router.replace("/login")
        }
    }
}
</script>
