import { createI18n } from "vue-i18n"
import fr from "@globals/langs/fr.json"
import moment from "moment"
import { LANGS } from "@globals/constants"

moment.locale(LANGS.FR)

export const i18n = createI18n({
    locale: LANGS.FR, // set locale
    fallbackLocale: LANGS.FR,
    legacy: true,
    messages: {
        fr //only load FR since its Admin
    }
})

export default i18n
