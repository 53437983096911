<template>
    <auth-layout>
        <form @submit.prevent="change">
            <div class="input-container">
                <label
                    for="token"
                    class="">
                    Code unique
                </label>
                <div class="c__input">
                    <input
                        id="token"
                        type="text"
                        required
                        disabled
                        v-model="token"
                        name="token" />
                </div>
                <p
                    class="error"
                    v-if="errors.token">
                    {{ errors.token }}
                </p>
            </div>
            <div class="input-container">
                <label
                    for="password"
                    class="">
                    Mot de passe
                </label>
                <div class="c__input">
                    <input
                        id="password"
                        type="password"
                        required
                        v-model="password"
                        name="password" />
                </div>
                <p
                    class="error"
                    v-if="errors.password">
                    {{ errors.password }}
                </p>
            </div>
            <div class="input-container">
                <label
                    for="confirmPassword"
                    class="">
                    Confirmer le mot de passe
                </label>
                <div class="c__input">
                    <input
                        id="confirmPassword"
                        type="password"
                        required
                        v-model="confirmPassword"
                        name="confirmPassword" />
                </div>
                <p
                    class="error"
                    v-if="errors.confirmPassword">
                    {{ errors.confirmPassword }}
                </p>
            </div>
            <div class="mt-6 flex items-center justify-center">
                <router-link
                    to="forgot-pwd"
                    class="btn--text">
                    Recommencer ?
                </router-link>
            </div>
            <div class="mt-6">
                <button
                    type="submit"
                    class="btn w-full">
                    Changer le mot de passe
                </button>
            </div>
            <div class="mt-6 flex flex-row items-center justify-center">
                <router-link
                    to="login"
                    class="btn--outline">
                    Se connecter
                </router-link>
            </div>
        </form>
    </auth-layout>
</template>
<script>
import * as types from "store/types"
import AuthLayout from "views/layouts/AuthLayout"

export default {
    name: "ChangePassword",
    components: { AuthLayout },
    data() {
        return {
            token: "",
            password: "",
            confirmPassword: "",
            errors: {}
        }
    },
    created() {
        this.token = this.$route.query.token || ""
    },
    methods: {
        change() {
            this.errors = {}
            this.$store
                .dispatch(types.RESET_PWD, {
                    token: this.token,
                    password: this.password,
                    confirmPassword: this.confirmPassword
                })
                .then((res) => {
                    this.$router.push("/login")
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
