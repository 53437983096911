<template>
    <div class="container py-12">
        <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <h3>Statistiques de clicks</h3>
        <canvas ref="clicksRef"></canvas>
      </div>
      <div>
        <h3>Statistiques de temps</h3>
        <canvas ref="timePastsRef"></canvas>
      </div>
    </div> -->

        <div class="mt-6">
            <h3>Jeux populaire</h3>
            <div>
                <table class="min-w-full divide-y divide-gray-200 mt-6">
                    <thead class="bg-gray-50">
                        <tr>
                            <custom-th> Ordre </custom-th>
                            <custom-th> Type </custom-th>
                            <custom-th> Nombre de partie </custom-th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                            v-for="(gameType, idx) in $store.state.stats.topGameTypes"
                            :key="gameType.key">
                            <custom-td> #{{ idx + 1 }} </custom-td>
                            <custom-td>
                                {{ $t(`global.gameTypes.${gameType.slug}`) }}
                            </custom-td>
                            <custom-td>
                                {{ gameType.count }}
                            </custom-td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-12">
            <h3>Statistiques</h3>
            <div class="form inline-block">
                <div class="input-container">
                    <label>Filtrer par</label>
                    <div class="c__select">
                        <select
                            class="form-select"
                            name="filterBy"
                            v-model="filterBy">
                            <option value="week">Semaine</option>
                            <option value="month">Mois</option>
                            <option value="year">Année</option>
                            <option value="all">Tous</option>
                        </select>
                    </div>
                </div>
            </div>
            <div>
                <table class="min-w-full divide-y divide-gray-200 mt-6">
                    <thead class="bg-gray-50">
                        <tr>
                            <custom-th> Date </custom-th>
                            <custom-th> Visite </custom-th>
                            <custom-th> Partie Anonyme </custom-th>
                            <custom-th> Conversion Anonyme </custom-th>
                            <custom-th> Visite Anonyme </custom-th>
                            <custom-th> Visite connecté </custom-th>
                            <custom-th> Visite étudiant </custom-th>
                            <custom-th> Visite professeur </custom-th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                            v-for="(stat, idx) in stats"
                            :key="stat.id">
                            <custom-td>
                                {{ stat.dayDate }}
                            </custom-td>
                            <custom-td>
                                {{ stat.visitCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.anonymousGameCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.anonymousConversionCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.anonymousVisitCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.connectedVisitCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.studentVisitCount }}
                            </custom-td>
                            <custom-td>
                                {{ stat.userVisitCount }}
                            </custom-td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"

import {
    Chart,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    Legend,
    Title
} from "chart.js"
import CustomTd from "components/tables/CustomTd"
import CustomTh from "components/tables/CustomTh"

Chart.register(
    LineElement,
    BarElement,
    LinearScale,
    Legend,
    Title,
    BarController,
    PointElement,
    LineController,
    CategoryScale
)

export default {
    name: "Dashboard",
    components: { CustomTh, CustomTd },
    data() {
        return {
            stats: [],
            filterBy: "week"
        }
    },
    created() {
        this.$store.dispatch(types.GET_TOP_GAME_TYPES)
        this.$store.dispatch(types.GET_STATS_CLICKS)
        this.$store.dispatch(types.GET_TIME_PASTS)

        this.fetchStats()
    },
    watch: {
        "$store.state.stats.clicks"(newValue) {
            this.initClickChart(newValue)
        },
        filterBy() {
            this.$nextTick(() => {
                this.fetchStats()
            })
        }
    },
    methods: {
        fetchStats() {
            this.$store.dispatch(types.GET_STATS, { filterBy: this.filterBy }).then((stats) => {
                this.stats = stats
            })
        },
        initClickChart(data) {
            return // todo
            if (this.clickChart) {
                this.clickChart.destroy()
                this.clickChart = null
            }
            this.clickChart = new Chart(this.$refs.clicksRef, {
                type: "bar",
                data: {
                    labels: data.map((click) => click.type.name),
                    datasets: [
                        {
                            label: "",
                            data: data.map((click) => click.count),
                            backgroundColor: ["rgba(41, 84, 148, 0.2)"],
                            borderColor: ["rgba(41, 84, 148, 1)"],
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    events: [],
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            })
        }
    }
}
</script>
