<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/word-groups"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux group de mots
            </router-link>
            <h1 class="pb-6">Modifier la liste de mot</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="wordGroup">
                    <word-group-form
                        @submit="submit"
                        :word-group="wordGroup"
                        :errors="errors" />
                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer la liste de mot
                        </button>
                        <button
                            type="submit"
                            class="btn">
                            Modifier
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import WordGroupForm from "views/word-groups/WordGroupForm"
import { LANGS } from "@globals/constants"

export default {
    name: "update-word-group",
    components: {
        WordGroupForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            wordGroup: null,
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.isLoading = true

        this.$store
            .dispatch(types.GET_WORD_GROUP_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.wordGroup = res

                //add missing langs
                Object.values(LANGS).forEach((lang) => {
                    let idx = this.wordGroup.traductions.findIndex((t) => t.lang === lang)
                    if (idx !== -1) return
                    this.wordGroup.traductions.push({
                        lang: lang,
                        text: ""
                    })
                })

                this.isLoading = false
            })
            .catch((err) => {
                this.isLoading = false
                this.$router.replace("/word-groups")
            })
    },
    methods: {
        remove() {
            if (!window.confirm("Effacer cette liste de mot. Êtes-vous sûr de vouloir continuer ?")) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_WORD_GROUP, this.wordGroup.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/word-groups")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true

            this.$store
                .dispatch(types.UPDATE_WORD_GROUP, this.wordGroup)
                .then((res) => {
                    this.wordGroup = res

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "La liste de mot a été enregistré",
                        title: "Liste de mot",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
