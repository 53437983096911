<template>
    <div class="students">
        <pagination-layout
            title="Écoles"
            create-title="Créer une nouvelle école"
            create-url="/schools/create"
            @search="onSearch"
            :data="schools">
            <template v-slot:header>
                <custom-th> Nom </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="school in currentPage"
                    :key="school.id">
                    <custom-td>
                        {{ school.name }}
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/schools/${school.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTd from "components/tables/CustomTd"
import CustomTh from "components/tables/CustomTh"
import { sortBy } from "lodash"
import ajax from "config/ajax"

export default {
    name: "schools",
    components: {
        CustomTh,
        PaginationLayout,
        CustomTd
    },
    data() {
        return {
            schools: []
        }
    },

    methods: {
        onSearch(searchValue) {
            ajax.get(`/schools/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.schools = sortBy(res.schools, "name")
            })
        }
    }
}
</script>
