const { objectToSelector } = require("./functions")
const DEFAULT_LANG = "fr"
module.exports.DEFAULT_LANG = DEFAULT_LANG

const LANGS = {
    FR: "fr",
    EN: "en",
    ES: "es",
    DE: "de",
    IT: "it",
    PT: "pt"
}
module.exports.LANGS = LANGS

const AVAILABLE_LANGS = {
    FR: "fr",
    EN: "en",
    ES: "es"
}
module.exports.AVAILABLE_LANGS = AVAILABLE_LANGS

const AVAILABLE_LEARNING_LANGS = {
    FR: "fr",
    EN: "en",
    ES: "es"
}
module.exports.AVAILABLE_LEARNING_LANGS = AVAILABLE_LEARNING_LANGS

const LEARNING_LANGS = {
    FR: LANGS.EN, //english per default
    EN: LANGS.FR, //french per default
    ES: LANGS.EN, //english per default
    DE: LANGS.EN, //english per default
    IT: LANGS.EN, //english per default
    PT: LANGS.EN //english per default
}
module.exports.LEARNING_LANGS = LEARNING_LANGS

//use for aws, etc
const LANG_CODES = {
    FR: "fr-CA",
    EN: "en-US",
    ES: "es-ES",
    DE: "de-DE",
    IT: "it-IT",
    PT: "pt-PT"
}

module.exports.LANG_CODES = LANG_CODES

//https://fakerjs.dev/api/localization.html
const FAKER_LANG_CODES = {
    FR: "fr_CA",
    EN: "en_CA",
    ES: "es",
    DE: "de",
    IT: "it",
    PT: "pt_PT"
}

module.exports.FAKER_LANG_CODES = FAKER_LANG_CODES

//Débutant, Intermédiaire, Avancé
const DIFFICULTY = {
    EASY: 1,
    MEDIUM: 2,
    HARD: 3,
    VERY_HARD: 4
}

module.exports.DIFFICULTY = DIFFICULTY

const GENDERS = {
    M: "m",
    F: "f",
    X: "x"
}
module.exports.GENDERS = GENDERS

const GRADES = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
}
module.exports.GRADES = GRADES

const PRODUCT_CATEGORIES = {
    AVATAR: "avatar",
    BADGE: "badge",
    MAIN_BACKGROUND: "main-background",
    BACKGROUND: "background"
}

module.exports.PRODUCT_CATEGORIES = PRODUCT_CATEGORIES

const GAME_TYPES = {
    CARDS: "cards",
    HEAR: "hear",
    MATCH: "match",
    TRANSLATE: "translate",

    //live stuff
    BATTLE: "battle",
    CONTEST: "contest"
}

module.exports.GAME_TYPES = GAME_TYPES

const USER_ROLES = {
    TEACHER: "teacher",
    PARENT: "parent",
    SCHOOL_MANAGER: "school-manager"
}

module.exports.USER_ROLES = USER_ROLES

const USER_TYPES = {
    USER: "user",
    STUDENT: "student",
    ANONYMOUS: "anonymous"
}

module.exports.USER_TYPES = USER_TYPES

const LEAGUE_TYPES = {
    PUBLIC: "public",
    PRIVATE: "private"
}

module.exports.LEAGUE_TYPES = LEAGUE_TYPES

const WORD_GROUP_TYPES = {
    LIST: "list",
    CUSTOM: "custom" //created by a school for student, etc
}

module.exports.WORD_GROUP_TYPES = WORD_GROUP_TYPES

const COUNTRIES = {
    CA: "Canada",
    US: "United States of America",
    FR: "France",
    MX: "Mexico",
    ES: "España"
}
module.exports.COUNTRIES = COUNTRIES

const PROVINCES_BY_COUNTRY = {
    [COUNTRIES.CA]: objectToSelector({
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NS: "Nova Scotia",
        NT: "Northwest Territories",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Québec",
        SK: "Saskatchewan",
        YT: "Yukon"
    })
}

module.exports.PROVINCES_BY_COUNTRY = PROVINCES_BY_COUNTRY

const STAT_TYPES = {
    PRODUCT: "product",
    GAME_TYPE: "game-type"
}
module.exports.STAT_TYPES = STAT_TYPES

const SOCKET_EVENTS = {
    NEW_NOTIFICATION: "NEW_NOTIFICATION",

    JOIN_LIVE_GAME: "JOIN_LIVE_GAME",
    START_LIVE_GAME: "START_LIVE_GAME",
    UPDATE_LIVE_GAME: "UPDATE_LIVE_GAME",

    USER_UPDATED_GAME: "USER_UPDATED_GAME",

    CLOSING_SOCKET: "CLOSING_SOCKET"
}

module.exports.SOCKET_EVENTS = SOCKET_EVENTS

const NOTIFICATION_TYPES = {
    LEAGUE_REQUEST: "league-request",
    LEAGUE_INVITATION: "league-invitation",
    GAME_INVITATION: "game-invitation"
}

module.exports.NOTIFICATION_TYPES = NOTIFICATION_TYPES

const PAYMENT_TYPES = {
    RENEW_LICENSE: "renew-license",
    LICENSE: "license",
    SUBSCRIPTION: "subscription"
}

module.exports.PAYMENT_TYPES = PAYMENT_TYPES

const BULK_DOCUMENT_TYPES = {
    CLASSROOM: "classroom"
}

module.exports.BULK_DOCUMENT_TYPES = BULK_DOCUMENT_TYPES

const FILTERED_CHOICES = {
    ALL: "all",
    MONTH: "month",
    YEAR: "year"
}

module.exports.FILTERED_CHOICES = FILTERED_CHOICES

const LICENSE_TYPES = {
    PARENT: "parent",
    STUDENT: "student",
    TEACHER: "teacher"
}

module.exports.LICENSE_TYPES = LICENSE_TYPES
