<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/words"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux mots
            </router-link>
            <h1 class="pb-6">
                Modifier le mot <span v-if="word">({{ word.id }})</span>
            </h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit"
                    v-if="word">
                    <word-form
                        ref="wordForm"
                        :word="word"
                        :errors="errors"></word-form>

                    <div class="flex flex-row justify-between items-center pt-6">
                        <button
                            type="button"
                            @click.prevent="remove"
                            class="btn--outline">
                            Effacer le mot
                        </button>
                        <div class="flex flex-row justify-between items-center space-x-6">
                            <button
                                type="submit"
                                class="btn">
                                Modifier
                            </button>
                        </div>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import WordForm from "views/words/WordForm"
import { LANGS } from "@globals/constants"

export default {
    name: "update-word",
    components: {
        WordForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            word: null,
            errors: {},
            isLoading: false
        }
    },

    created() {
        this.isLoading = true
        this.$store
            .dispatch(types.GET_WORD_BY_UUID, this.$route.params.uuid)
            .then((res) => {
                this.word = res

                //add missing langs
                Object.values(LANGS).forEach((lang) => {
                    let idx = this.word.traductions.findIndex((t) => t.lang === lang)
                    if (idx !== -1) return
                    this.word.traductions.push({
                        lang: lang,
                        sentences: [],
                        questions: [],
                        imagePath: null,
                        imageUrl: null,
                        text: "",
                        description: "",
                        rule: ""
                    })
                })

                this.isLoading = false
            })
            .catch((err) => {
                this.isLoading = false
                this.$router.replace("/words")
            })
    },
    methods: {
        remove() {
            if (
                !window.confirm(
                    "Effacer ce mot l'enlèvera de tous les listes de mots et tous les élèves qui ont fait ce mot perdront leur progrès. Êtes-vous sûr de vouloir continuer ?"
                )
            ) {
                return
            }
            this.isLoading = true
            this.$store
                .dispatch(types.DELETE_WORD, this.word.uuid)
                .then(() => {
                    this.isLoading = false
                    this.$router.replace("/words")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        submit() {
            this.isLoading = true

            this.$store
                .dispatch(types.UPDATE_WORD, this.word)
                .then((res) => {
                    // update sentences so we get uuid, etc
                    this.word = res

                    this.$refs.wordForm.loadSound() //make sure sound sound right

                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "Le mot a été enregistré",
                        title: "Mot",
                        type: "success"
                    })
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
