<template>
    <router-link
        :to="href"
        class="w-full group flex items-center px-2 py-2 text-base font-medium rounded-md"
        :class="`${isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}`">
        <Icons
            v-if="iconName"
            :icon="iconName"
            class="mr-4 flex-shrink-0 h-6 w-6"
            :class="`${isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'}`" />
        <slot> </slot>
    </router-link>
</template>

<script>
import Icons from "components/globals/Icons"
export default {
    name: "MenuItem",
    components: { Icons },
    props: {
        isActive: Boolean,
        href: String,
        iconName: String
    }
}
</script>
