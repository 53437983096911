<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/classrooms"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux classes
            </router-link>
            <h1 class="pb-6">Créer une nouvelle classe</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit">
                    <classroom-form
                        :errors="errors"
                        :classroom="classroom" />

                    <div class="flex flex-row justify-end items-center pt-6">
                        <button
                            type="submit"
                            class="btn">
                            Créer
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import ClassroomForm from "views/classrooms/ClassroomForm"

export default {
    name: "create-classroom",
    components: {
        ClassroomForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            classroom: {
                title: "",
                grade: 12,
                schoolId: null,
                teacherId: null,
                students: []
            },

            errors: {},
            isLoading: false
        }
    },
    methods: {
        async submit() {
            this.isLoading = true

            this.classroom.teacherId = this.classroom.teacherId === "" ? null : this.classroom.teacherId
            this.classroom.schoolId = this.classroom.schoolId === "" ? null : this.classroom.schoolId

            this.$store
                .dispatch(types.CREATE_CLASSROOM, this.classroom)
                .then((newClassroom) => {
                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: "Classe",
                        message: "La classe a été créé",
                        type: "success"
                    })
                    this.$router.push(`/classrooms/${newClassroom.uuid}`)
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
