import * as types from "store/types"
import ajax from "config/ajax"
// Static
const existingToken = window.localStorage.getItem("kwordz:jwt")

// initial state, with craft backend or empty
const state = {
    user: null,
    jwt: existingToken || null
}

// getters, make function easy to access by vue
const getters = {}

// actions
const actions = {
    [types.UPDATE_USER](store, payload) {
        return ajax.put("/me", payload).then((res) => {
            store.commit(types.UPDATE_USER, res.user)
        })
    },
    [types.GET_USER](store, payload) {
        return ajax.get("/me").then((res) => {
            store.commit(types.GET_USER, res.user)
            return Promise.resolve(res.user)
        })
    },
    [types.FORGOT_PWD](store, email) {
        return ajax.post("/auth/forgot-pwd", { email: email })
    },
    [types.RESET_PWD](store, payload) {
        return ajax.post("/auth/reset-pwd", payload)
    },
    [types.VERIFY_EMAIL](store, payload) {
        return ajax.post("/auth/verify").then((res) => {
            store.commit(types.GET_USER, res.user)
            return Promise.resolve(res.user)
        })
    },
    /*[types.REGISTER] (store, payload) {
      return ajax.post('/auth/register', payload)
        .then(res => {
          if (!res.jwt) {
            throw new Error('Veuillez verifier vos informations')
          }
          store.commit(types.JWT, res.jwt)
          return store.dispatch(types.GET_USER)
        })
        .then(() => {
          return store.dispatch(types.GET_DATA)
        })
    }, */
    [types.LOGIN](store, payload) {
        return ajax
            .post("/auth/login", payload)
            .then((res) => {
                if (!res.jwt) {
                    throw new Error("Veuillez verifier vos informations")
                }
                store.commit(types.JWT, res.jwt)
                return store.dispatch(types.GET_USER)
            })
            .then(() => {
                return store.dispatch(types.GET_DATA)
            })
    }
}

// mutations
const mutations = {
    [types.GET_USER](state, user) {
        state.user = user
    },
    [types.JWT](state, jwt) {
        state.jwt = jwt
        window.localStorage.setItem("kwordz:jwt", jwt)
        ajax.defaults.headers.common.Authorization = `Bearer ${jwt}`
    },
    [types.UPDATE_USER](state, newUser) {
        state.user = newUser
    },
    [types.LOGOUT](state) {
        state.user = null
        state.jwt = ""
        window.localStorage.removeItem("kwordz:jwt")
        ajax.defaults.headers.common.Authorization = ""
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
