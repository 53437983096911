<template>
    <div class="classrooms">
        <pagination-layout
            :data="classrooms"
            title="Classes"
            create-title="Créer une nouvelle classe"
            @search="onSearch"
            create-url="/classrooms/create">
            <template v-slot:header>
                <custom-th> Nom de la classe </custom-th>
                <custom-th> Grade </custom-th>
                <custom-th> Professeur </custom-th>
                <custom-th> École </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="classroom in currentPage"
                    :key="classroom.id">
                    <custom-td>
                        {{ classroom.title }}
                    </custom-td>
                    <custom-td>
                        {{ classroom.grade }}
                    </custom-td>
                    <custom-td>
                        <span v-if="$store.getters.getTeacher(classroom.teacherId)">
                            {{ $store.getters.getTeacher(classroom.teacherId).name }}
                        </span>
                        <span v-else> Aucun </span>
                    </custom-td>
                    <custom-td>
                        <span v-if="$store.getters.getSchool(classroom.schoolId)">
                            {{ $store.getters.getSchool(classroom.schoolId).name }}
                        </span>
                        <span v-else> Aucun </span>
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/classrooms/${classroom.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import * as types from "store/types"
import { sortBy } from "lodash"
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTh from "components/tables/CustomTh"
import CustomTd from "components/tables/CustomTd"
import ajax from "config/ajax"

export default {
    name: "classrooms",
    components: {
        CustomTd,
        CustomTh,
        PaginationLayout
    },
    created() {
        this.$store.dispatch(types.GET_SCHOOLS)
        this.$store.dispatch(types.GET_SCHOOL_USERS)
    },
    data() {
        return {
            classrooms: []
        }
    },
    methods: {
        onSearch(searchValue) {
            ajax.get(`/classrooms/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.classrooms = sortBy(res.classrooms, "grade")
            })
        }
    }
}
</script>
