<template>
    <div class="bg-white py-3 flex items-center justify-between">
        <div class="flex-1 flex justify-between sm:hidden">
            <a
                @click.prevent="goPrev"
                class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Précédent
            </a>
            <a
                @click.prevent="goNext"
                class="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Suivant
            </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Affichage de
                    <span
                        class="font-medium"
                        v-if="pagesCount > 0"
                        >{{ currentPage }}</span
                    >
                    <span
                        class="font-medium"
                        v-else
                        >0</span
                    >
                    à
                    <span class="font-medium">{{ pagesCount }}</span>
                    sur
                    <span class="font-medium">{{ totalResults }}</span>
                    résultats
                </p>
            </div>
            <div>
                <nav
                    class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination">
                    <a
                        @click.prevent="goPrev"
                        class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Précédent</span>
                        <Icons
                            icon="chevron-left"
                            class="h-5 w-5" />
                    </a>
                    <a
                        @click.prevent="goNext"
                        class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Suivant</span>
                        <!-- Heroicon name: solid/chevron-right -->
                        <Icons
                            icon="chevron-right"
                            class="h-5 w-5" />
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import Icons from "components/globals/Icons"

export default {
    name: "pagination",
    components: { Icons },
    props: {
        totalResults: {
            type: Number
        },
        pagesCount: {
            type: Number
        },
        currentPage: {
            type: Number
        }
    },
    computed: {
        currentPageIdx() {
            return this.currentPage - 1
        }
        /*
    cleanPages() {
    //todo
      if (this.pagesCount < 10) {
        return Array.from(Array(this.pagesCount).keys()).map((p, idx) => ({
          label: idx + 1,
          count: idx,
          click: idx
        }))
      }
      let fivePagesBehind = this.currentPage - 5;
      let fivePagesAfter = this.currentPage + 5;

      if (fivePagesBehind < 0) fivePagesBehind = 0
      if (fivePagesAfter > this.pagesCount) fivePagesAfter = this.pagesCount
      return [
        ...Array.from(Array(this.pagesCount).keys()).map((p, idx) => ({
          label: idx + 1,
          count: idx,
          click: idx
        })),
        {
          label: '...',
          count: '...',
          click: this.currentPage + 5
        },
        ...Array.from(Array(this.pagesCount).keys()).map((p, idx) => ({
          label: idx + 1,
          count: idx,
          click: idx
        })),
      ]
    } */
    },
    methods: {
        goPrev() {
            if (this.currentPageIdx <= 0) {
                return
            }
            this.$emit("goToPage", this.currentPageIdx - 1)
        },
        goNext() {
            if (this.currentPage >= this.pagesCount) {
                return
            }
            this.$emit("goToPage", this.currentPageIdx + 1)
        }
    }
}
</script>
