<template>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <slot></slot>
    </td>
</template>

<script>
export default {
    name: "custom-td"
}
</script>
