import { createStore } from "vuex"
import auth from "./modules/auth"
import data from "./modules/data"
import stats from "./modules/stats"

export default createStore({
    modules: {
        auth,
        data,
        stats
    }
})
