<template>
    <div class="c__upload-sound">
        <div
            class="w-full"
            :class="soundContainerClass"
            v-if="!showUploads && soundUrl">
            <div class="flex flex-row justify-start items-center space-x-3">
                <audio
                    controls
                    ref="audioTag">
                    <source
                        :src="soundUrl"
                        :type="`${soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
                <button
                    class="btn h-full ml-2"
                    type="button"
                    @click.prevent="deleteSound">
                    <Icons
                        class="h-4 w-auto inline-block text-white"
                        icon="trash" />
                </button>
            </div>
        </div>
        <div
            v-else
            class="flex flex-row justify-center items-center h-32 bg-gray-200 border border-solid border-gray-400 hover:bg-gray-300 transition duration-200 cursor-pointer"
            @click.prevent="modalOpen = true">
            <Icons
                icon="upload"
                class="block w-6 h-6 text-gray-800" />
        </div>
        <modal
            v-if="modalOpen"
            :show="modalOpen"
            @close="modalOpen = false">
            <div class="flex flex-row flex-wrap justify-start items-stretch h-full">
                <div class="w-full p-2">
                    <input
                        type="file"
                        class="hidden"
                        ref="fileInput"
                        accept="audio/mpeg"
                        @change="updateSound" />
                    <div
                        class="dropzone py-2 border border-dashed bg-gray-200 border-gray-400 rounded-sm h-full w-full flex flex-col justify-center items-center"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="onDrop">
                        <loader v-if="loading && showLoader"></loader>
                        <p
                            class="text-sm text-gray-500"
                            v-if="!loading">
                            Glissez déposer ou
                        </p>
                        <button
                            v-if="!loading"
                            class="btn--secondary mt-2"
                            type="button"
                            @click.prevent="selectNewSound">
                            Téléverser
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Loader from "components/globals/Loader"
import Modal from "components/globals/Modal"
import Icons from "components/globals/Icons"
import ajax from "config/ajax"

export default {
    name: "upload-sound",
    components: {
        Modal,
        Loader,
        Icons
    },
    props: {
        showLoader: {
            type: Boolean,
            required: false,
            default: () => true
        },
        path: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: false
        },
        fileFolder: {
            type: String,
            default: null
        },
        soundContainerClass: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            modalOpen: false,
            soundPath: null,
            soundUrl: null,
            loading: false,
            showUploads: false
        }
    },
    watch: {
        path(value) {
            this.soundPath = value
        },
        url(value) {
            this.soundUrl = value
        }
    },
    created() {
        if (this.path) {
            this.soundPath = this.path
        }

        if (this.url) {
            this.soundUrl = this.url
        }
    },
    methods: {
        onSelect(data) {
            this.soundUrl = data.url
            this.soundPath = data.path

            this.$emit("upload", {
                url: data.url,
                path: data.path
            })

            this.modalOpen = false
        },
        updateSound() {
            if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                this.uploadSound(this.$refs.fileInput.files[0])
            }
        },
        uploadSound(file) {
            if (!file) return
            const data = new FormData()
            if (this.fileFolder) {
                data.append("folderPath", this.fileFolder)
            }
            data.append("file", file)

            this.loading = true
            ajax.post("/globals/upload", data).then((data) => {
                this.loading = false
                if (!data.success) return

                this.soundUrl = data.url
                this.soundPath = data.path

                this.modalOpen = false

                this.$emit("upload", {
                    url: data.url,
                    path: data.path
                })
            })
        },

        selectNewSound() {
            this.$refs.fileInput.click()
        },
        onDrop(e) {
            if (e.dataTransfer.files.length > 0) {
                this.uploadSound(e.dataTransfer.files[0])
                // only one
            }
        },
        deleteSound() {
            this.soundUrl = null
            this.soundPath = null

            this.$emit("upload", {
                url: null,
                path: null
            })
        }
    }
}
</script>
