<template>
    <div>
        <div class="grid grid-cols-2 gap-6">
            <div class="input-container">
                <label>Prénom</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="firstName"
                        v-model="student.firstName" />
                </div>
                <p
                    class="error"
                    v-if="errors.firstName">
                    {{ errors.firstName }}
                </p>
            </div>

            <div class="input-container">
                <label>Nom</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="lastName"
                        v-model="student.lastName" />
                </div>
                <p
                    class="error"
                    v-if="errors.lastName">
                    {{ errors.lastName }}
                </p>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6">
            <div class="input-container">
                <label>Région</label>
                <div class="c__select">
                    <select
                        class="form-select"
                        name="region"
                        v-model="student.region">
                        <option
                            value=""
                            selected>
                            Sélectionner
                        </option>
                        <option value="QC">Québec</option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.region">
                    {{ errors.region }}
                </p>
            </div>
            <div class="input-container">
                <label>Pays</label>
                <div class="c__select">
                    <select
                        class="form-select"
                        name="country"
                        v-model="student.country">
                        <option
                            value=""
                            selected>
                            Sélectionner
                        </option>
                        <option value="CA">Canada</option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.country">
                    {{ errors.country }}
                </p>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6">
            <div class="input-container">
                <label>Code</label>
                <div class="c__input">
                    <input
                        disabled
                        type="text"
                        name="shortcode"
                        v-model="student.shortcode" />
                </div>
            </div>
            <div class="input-container">
                <label>Username</label>
                <div class="c__input">
                    <input
                        disabled
                        type="text"
                        name="username"
                        v-model="student.username" />
                </div>
                <p
                    class="error"
                    v-if="errors.username">
                    {{ errors.username }}
                </p>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-6">
            <div class="input-container">
                <label>Points K</label>
                <div class="c__input">
                    <input
                        type="number"
                        min="0"
                        name="pointsK"
                        v-model="student.pointsK" />
                </div>
            </div>
            <div class="input-container">
                <label>Points Z</label>
                <div class="c__input">
                    <input
                        type="number"
                        min="0"
                        name="pointsZ"
                        v-model="student.pointsZ" />
                </div>
            </div>
            <div class="input-container">
                <label>Points XP</label>
                <div class="c__input">
                    <input
                        type="number"
                        min="0"
                        name="pointsXP"
                        v-model="student.pointsXP" />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-6">
            <item-select
                select
                v-model="student.lang"
                label="Langue"
                :data="Object.values(AVAILABLE_LANGS)"
                :error="errors.lang"
                :value-key="null"
                :label-key="(item) => $t(`global.langs.${item}`)" />
            <item-select
                select
                v-model="student.learningLang"
                label="Langue d'apprentissage"
                :data="Object.values(AVAILABLE_LEARNING_LANGS)"
                :value-key="null"
                :label-key="(item) => $t(`global.langs.${item}`)"
                :error="errors.learningLang" />

            <item-select
                select
                v-model="student.gender"
                label="Genre"
                :data="Object.values(GENDERS)"
                :value-key="null"
                :label-key="(item) => $t(`global.genders.${item}`)"
                :error="errors.gender" />
        </div>

        <div class="grid grid-cols-2 gap-6">
            <item-select
                select
                hide-empty
                v-model="student.difficulty"
                :data="Object.values(DIFFICULTY)"
                label="Niveau"
                :label-key="(item) => $t(`global.difficulty.${item}`)"
                :value-key="null"
                :error="errors.difficulty" />

            <item-select
                select
                v-model="student.grade"
                :data="Object.values(GRADES)"
                label="Année"
                :error="errors.grade"
                :value-key="null"
                :label-key="(item) => item" />
        </div>

        <div class="grid grid-cols-2 gap-6">
            <item-select
                @search="onSchoolSearch"
                v-model="student.schoolId"
                :data="schools"
                label="École"
                label-key="name"
                value-key="id"
                :error="errors.schoolId" />
        </div>

        <item-select
            @search="onSchoolUserSearch"
            v-model="student.teacherId"
            :error="errors.teacherId"
            label="Professeur (selon l'école)"
            :data="schoolUsers"
            :label-key="(item) => `${item.name} (${item.email})`"
            value-key="id" />

        <items-select
            @search="onClassroomSearch"
            v-model="student.classrooms"
            :error="errors.classroomIds"
            label="Classes (selon l'école + professeur)"
            :data="searchedClassrooms"
            label-key="title"
            value-key="id" />
    </div>
</template>

<script>
import ItemsSelect from "components/forms/ItemsSelect"
import ItemSelect from "components/forms/ItemSelect"
import ajax from "config/ajax"
import { DIFFICULTY, GENDERS, GRADES, USER_ROLES, AVAILABLE_LEARNING_LANGS, AVAILABLE_LANGS } from "@globals/constants"

export default {
    name: "student-form",
    components: {
        ItemSelect,
        ItemsSelect
    },
    props: {
        student: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            DIFFICULTY,
            AVAILABLE_LANGS,
            AVAILABLE_LEARNING_LANGS,
            GENDERS,
            GRADES,

            schools: [],
            schoolUsers: [],
            searchedClassrooms: []
        }
    },
    created() {
        if (this.student.schoolId) {
            this.onSchoolSearch("")
        }
        if (this.student.classrooms.length > 0) {
            this.onClassroomSearch("")
        }
        if (this.student.teacherId) {
            this.onSchoolUserSearch("")
        }
    },
    watch: {
        classrooms(newValue) {
            //add word to wordIds
            if (newValue.length === 0) return
            let newClassroom = newValue[newValue.length - 1]
            this.student.classrooms.push(newClassroom.id) //keep selected word so we can see the text
        }
    },
    methods: {
        onSchoolSearch(searchValue) {
            ajax.get(`/schools/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.schools = res.schools
            })
        },
        onSchoolUserSearch(searchValue) {
            ajax.get(`/school-users/search`, {
                params: {
                    role: USER_ROLES.TEACHER,
                    search: searchValue,
                    ...(this.student.schoolId
                        ? {
                              schoolId: this.student.schoolId
                          }
                        : {})
                }
            }).then((res) => {
                this.schoolUsers = res.schoolUsers
            })
        },
        onClassroomSearch(searchValue) {
            ajax.get(`/classrooms/search`, {
                params: {
                    search: searchValue,
                    ...(this.student.schoolId
                        ? {
                              schoolId: this.student.schoolId
                          }
                        : {}),
                    ...(this.student.teacherId
                        ? {
                              teacherId: this.student.teacherId
                          }
                        : {})
                }
            }).then((res) => {
                this.searchedClassrooms = res.classrooms
            })
        }
    }
}
</script>
