module.exports.objectToSelector = (obj, toLowerCase = false, reverse = false) => {
    return Object.keys(obj).map((k) => {
        let value = k
        if (!isNaN(value)) {
            value = parseFloat(k)
        }
        if (toLowerCase && typeof value === "string") {
            value = k.toLowerCase()
        }
        if (reverse) {
            return { value: obj[k], label: value }
        }
        return { value: value, label: obj[k] }
    })
}

module.exports.sleep = async (ms) => {
    return new Promise((r) => setTimeout(r, ms))
}

module.exports.createArray = (length) => {
    return Array.from({ length: length }, (v, i) => i + 1)
}

//OLD CODE. Written by charles darras
module.exports.getExperienceByXp = (xp) => {
    if (xp < 0) xp = 0
    let level = Math.floor(Math.pow((xp + 900) / 1000, 16 / 19))
    level++ //begin at level 1
    let toNextLevel = Math.floor(1000 * Math.pow(level + 1, 19 / 16) - 900)
    let previous = Math.floor(1000 * Math.pow(level, 19 / 16) - 900)

    let levelPercentage = Math.floor((xp / toNextLevel) * 100)

    return {
        level,
        toNextLevel,
        previous,
        levelPercentage
    }
}

module.exports.msToTime = (s) => {
    let hundreth = s % 1000
    s = (s - hundreth) / 1000
    let seconds = s % 60
    s = (s - seconds) / 60
    let minutes = s % 60
    let hours = (s - minutes) / 60

    return {
        hours,
        minutes,
        seconds,
        hundreth
    }
}
