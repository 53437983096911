export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FORGOT_PWD = "FORGOT_PWD"
export const JWT = "JWT"
export const GET_USER = "GET_USER"
export const REGISTER = "REGISTER"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const RESET_PWD = "RESET_PWD"
export const VERIFY_EMAIL = "VERIFY_EMAIL"

export const CREATE_WORD = "CREATE_WORD"
export const UPDATE_WORD = "UPDATE_WORD"
export const GET_WORDS = "GET_WORDS"
export const GET_WORD_BY_UUID = "GET_WORD_BY_UUID"
export const DELETE_WORD = "DELETE_WORD"

export const CREATE_WORD_GROUP = "CREATE_WORD_GROUP"
export const UPDATE_WORD_GROUP = "UPDATE_WORD_GROUP"
export const GET_WORD_GROUPS = "GET_WORD_GROUPS"
export const GET_WORD_GROUP_BY_UUID = "GET_WORD_GROUP_BY_UUID"
export const DELETE_WORD_GROUP = "DELETE_WORD_GROUP"

export const CREATE_CLASSROOM = "CREATE_CLASSROOM"
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM"
export const GET_CLASSROOMS = "GET_CLASSROOMS"
export const GET_CLASSROOM_BY_UUID = "GET_CLASSROOM_BY_UUID"
export const GET_CLASSROOM_BY_ID = "GET_CLASSROOM_BY_ID"
export const DELETE_CLASSROOM = "DELETE_CLASSROOM"

export const CREATE_STUDENT = "CREATE_STUDENT"
export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENT_BY_UUID = "GET_STUDENT_BY_UUID"
export const DELETE_STUDENT = "DELETE_STUDENT"

export const GET_SCHOOLS = "GET_SCHOOLS"
export const CREATE_SCHOOL = "CREATE_SCHOOL"
export const UPDATE_SCHOOL = "UPDATE_SCHOOL"
export const DELETE_SCHOOL = "DELETE_SCHOOL"
export const GET_SCHOOL_BY_UUID = "GET_SCHOOL_BY_UUID"

export const GET_DATA = "GET_DATA"

export const GET_UPLOADS = "GET_UPLOADS"
export const ADD_UPLOAD = "ADD_UPLOAD"

export const GET_GLOBAL = "GET_GLOBAL"

export const GET_SCHOOL_USERS = "GET_SCHOOL_USERS"
export const CREATE_SCHOOL_USER = "CREATE_SCHOOL_USER"
export const UPDATE_SCHOOL_USER = "UPDATE_SCHOOL_USER"
export const DELETE_SCHOOL_USER = "DELETE_SCHOOL_USER"
export const GET_SCHOOL_USER_BY_UUID = "GET_SCHOOL_USER_BY_UUID"

export const GET_STATS_CLICKS = "GET_STATS_CLICKS"
export const GET_TIME_PASTS = "GET_TIME_PASTS"
export const GET_TOP_GAME_TYPES = "GET_TOP_GAME_TYPES"
export const GET_STATS = "GET_STATS"

export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCT_BY_UUID = "GET_PRODUCT_BY_UUID"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
